/* eslint-disable max-len */
const GlobalVariables = require("./globalVariables"); // Import the necessary module

class User {
  static getEndpoints() {
    return {
      getUsersByDomain: "/api/user/company-user-list",
      getUserInfo: "/api/user/get-user-integrations-data",
    };
  }

  static async getUsersByDomain(domain) {
    const ENDPOINT =
      GlobalVariables.getAPIURL() + User.getEndpoints().getUsersByDomain;
    // eslint-disable-next-line max-len
    return await fetch(`${ENDPOINT}?companydomain=${domain}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          return { success: true, response: data.userList };
        } else {
          return { success: false, reason: "internal server error" };
        }
      })
      .catch((error) => {
        console.log("GET USERS BY DOMAIN ERROR: ", error);
        console.error(error);
        return { success: false, reason: "internal server error" };
      });
  }

  static async getUserInfo(email) {
    const ENDPOINT =
      GlobalVariables.getAPIURL() + User.getEndpoints().getUserInfo;
    // eslint-disable-next-line max-len
    return await fetch(`${ENDPOINT}?email=${email}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        if (data.success) {
          return { success: true, response: data.data };
        } else {
          return { success: false, reason: "internal server error" };
        }
      })
      .catch((error) => {
        console.log("GET USERS BY DOMAIN ERROR: ", error);
        console.error(error);
        return { success: false, reason: "internal server error" };
      });
  }
}

module.exports = User;
