import "./ButtonInstance.css";
import {useState, useEffect} from "react";

const samplePrompts = ["Summarize a document", "Generate project outline",
    "Create a to-do list", "Write a blog post", "Generate a list of ideas", "Write a summary",
    "Write a poem about love", "Tell me a joke", "What happened in 1969", "Give me a travel itinerary",
    "Write a book review", "Write a product description", "Write a movie review", "Write a song",
    "Advise on quick stress relief", "Translate 'Hello' to French", "Write a short story",
    "Brief history of the internet", "Write a recipe", "Write a love letter",
    "Write a speech", "How to make a paper airplane", "Write a letter to a friend",
    "What are the benefits of meditation", "Write a letter to the editor", "Write a letter to a company",
    "Explain how photosynthesis works", "Generate a tech startup name"];

const sampleSelectedTextActions = ["Improve writing", "Summarize", "Make shorter", "Translate"];

const mortgagePrompts = [
    "Summarize a loan application",
    "Write a client follow-up email",
    "Create a to-do list for loan processing",
    "Draft a loan pre-approval letter",
    "Tips for new loan officers",
    "Write a blog post about mortgage loans",
    "Write a loan investment memo",
    "What is LTV in mortgage lending",
    "What is CLTV in mortgage lending",
    "What are interest rates",
    "Why do lending rates change?",
    "Write a summary of loan options",
    "Tell me a mortgage industry trend",
    "Write a loan origination report template",
    "Write a mortgage market analysis report",
    "Draft an email to a mortgage broker",
    "Advise on improving credit scores",
    "Write a success story of a closed loan",
    "Case study template of successful loan approval",
    "Write a checklist for first-time homebuyers",
    "Write a thank-you letter to a client",
    "How to explain loan terms to clients",
    "Email a loan underwriter",
    "Benefits of loan refinancing",
    "Explain how loan amortization works",
    "Generate a loan marketing campaign idea"
];


const ButtonInstance = (props) => {
    const [textareaValue, setTextareaValue] = useState("");
    const [suggestedPrompts, setSuggestedPrompts] = useState([]);
    const [selectedType, setSelectedType] = useState("What do you want to do with this text?");
    const [linesCount, setLinesCount] = useState(0);
    const [viewTitle, setViewTitle] = useState("Selected text");
    const [maxCharsPerLine, setMaxCharsPerLine] = useState(30);
    const [selectedTextActions, setSelectedTextActions] =
        useState(sampleSelectedTextActions);

    const clientID = props.clientID;
    const data = props.data;
    const currentlyTyping = props.currentlyTyping;
    const numberOfMessagesInChat = props.numberOfMessagesInChat;
    const uid = props.uid;

    const [selectedText, setSelectedText] = useState(data?.action === "selectedText" &&
        data?.text ? data.text : "");

     // eslint-disable-next-line no-undef
     const chatInfo = document.querySelector("#addy-chat-info");
    if (selectedText && selectedText.length && chatInfo) {
        chatInfo.remove();
    
    }

    useEffect(() => {
        if (clientID && uid) {
            // Whenever a user selects text on the page, listen for it
            listenForSelectedFromClientWebpage(clientID);
        }
        // Set suggested prompts to be 10 random prompts from samplePrompts
        const randomPrompts = ["Write an email"];
        const randomPrompts2 = mortgagePrompts.sort(() => 0.5 - Math.random());
        setSuggestedPrompts(randomPrompts.concat(randomPrompts2.slice(0, 10)));

        if (data && data["action"] === "selectedText") {
            setSelectedText(data["text"]);
        }
    }, [clientID]); // Run this effect when clientID changes

    useEffect(() => {
        // remove chat info onboarding text if selected text is present
        if (selectedText && selectedText.length && chatInfo) {
            chatInfo.remove();
        }
    }, [selectedText]); // Reload component on selected text change

    // Reload component on currentlyTyping change
    useEffect(() => { }, [currentlyTyping]);

    // Reload when props change
    useEffect(() => {
        if (props.currentEmailAction === "Analyze attachment" && props.selectedAttachmentName) {
            setSelectedText(props.selectedAttachmentName);
            setSelectedType("More ideas for you");
            setViewTitle("Chat with your document");
            setSelectedTextActions(["Extract text", "Translate", "Write a blog post about this document"]);
            // get the analyze attachment parent indicator
            const analyzeAttachmentParent = document.querySelector(".selected-text-parent");
            if (analyzeAttachmentParent) {
                analyzeAttachmentParent.classList.add("analyze-attachment-parent");
            }
        } else {
            if (selectedText) {
                setSelectedType("What do you want to do with this text?");
                setViewTitle("Selected text");
                setSelectedTextActions(sampleSelectedTextActions);
            }
            
        }
        if (props.maxCharsPerLine) {
            setMaxCharsPerLine(props.maxCharsPerLine);
        }
    }, [props]);

    // Reload when linesCount changes
    useEffect(() => { }, [linesCount]);

    // Handle change event of textarea
    const handleChange = (event) => {
        setTextareaValue(event.target.value);
        const textareaWidth = event.target.getBoundingClientRect().width;
        const lineBreakCount = event.target.value.split(/\r\n|\r|\n/).length;
        const lengthBasedCount = calculateLineCount(textareaWidth);
        const count = event.target.value === "" ? 1 :
            Math.max(lineBreakCount, lengthBasedCount);
        setLinesCount(count <= 5 ? count : 5);
    };

    const calculateLineCount = (elementWidth) => {
        const charWidth = 5;
        const maxCharactersPerLine = Math.floor(elementWidth / charWidth);
        return Math.ceil(textareaValue.length / maxCharactersPerLine);
    };


    // Handle key down event of textarea
    const handleKeyDown = (event) => {
        if (event.key === "Enter" && (event.shiftKey || event.altKey)) {
            // If Shift+Enter or Alt+Enter is pressed, create a new line
            event.stopPropagation();
            if (linesCount < 5) {
                setTextareaValue((prevValue) => prevValue + "\n");
            }
        } else if (event.key === "Enter") {
            onClick();
            event.preventDefault();
        }
    };

    const onClick = () => {
        if (!textareaValue || currentlyTyping) return;
        props.onClick(textareaValue, selectedText);
        setTextareaValue("");
        setLinesCount(1);
        // Reset the selected text
        setSelectedText("");
    };

    const onSuggestedPromptClick = (prompt) => {
        if (currentlyTyping) return;
        // Reset the selected text
        props.onClick(prompt, selectedText);
        setSelectedText("");
    };

    const onSelectedTextClose = () => {
        setSelectedText("");
    };

    const closeHotTip = () => {
        const hotTipDiv = document.getElementById("hot-tip-div");
    };

    const listenForSelectedFromClientWebpage = async (id) => {
        // eslint-disable-next-line no-undef
        window.addEventListener("message", (event) => {
            const data = event.data;
            // console.log("Data received from window post message", data);
            if (data && data.key) {
                const action = data.key;
                const text = data.value;
                switch (action) {
                case "selectedText":
                    setSelectedText(text);
                    break;
                default:
                    break;
                }
            }
        });
    };

    return (
        <div className="button-instance">
            {selectedText?.trim() ?
                <div className="selected-text-container">

                    <div className="selected-text-parent">
                        <div className="selected-text-header-parent">

                            <div className="selected-text-header">
                                {viewTitle}
                            </div>

                            <div className="selected-text-close-button" onClick={() => onSelectedTextClose()}>
                                <img width="12" height="12" src="/close.svg" />
                            </div>

                        </div>
                        <div className="selected-text">
                            {selectedText.length > 250 ? selectedText.substring(0, 250) + "..." : selectedText}
                        </div>
                    </div>

                    <div>
                        <div className="action-title">
                            {selectedType}
                        </div>

                        <div className="selected-text-action-div">
                            {selectedTextActions.map((action, index) => (
                                <div onClick={() => onSuggestedPromptClick(action)} key={index} className="suggestion-button selected-text-action">
                                    {action}
                                </div>
                            ))}
                        </div>


                    </div>

                </div> :

                numberOfMessagesInChat === 0 ?

                    !props.hideHotTipContainer && <div className="hot-tip-parent-container">
                        <div className="selected-text-parent hot-tip-parent" id="hot-tip-div">
                            <div className="selected-text-header-parent">

                                <div className="selected-text-header">
                                    Hot tip!
                                </div>

                                {/* <div className="selected-text-close-button" onClick={() => closeHotTip()}>
                                    <img width="12" height="12" src="/close.svg" />
                                </div> */}

                            </div>
                            <div className="selected-text">
                                Press and hold the <mark className="focus-text-mark">`Control` </mark>
                                key, then highlight text on any website
                                to quickly open Addy AI. Try it now!
                            </div>
                        </div>

                        {!props.hideSuggestedPrompts && <div className="lightning-instance-parent">
                            {props.showMoreSuggestionsForYouTitle && <div className="more-suggestions-title">More suggestions for you</div>}
                            <div className="lightning-instance tooltip-5">
                                {suggestedPrompts.map((prompt, index) => (
                                    <div onClick={() => onSuggestedPromptClick(prompt)} key={index} className="suggestion-button">
                                        {prompt}
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div> :

                    !props.hideSuggestedPrompts && <div className="lightning-instance-parent">
                        {props.showMoreSuggestionsForYouTitle && <div className="more-suggestions-title">More suggestions for you</div>}
                        <div className="lightning-instance tooltip-5">
                            {suggestedPrompts.map((prompt, index) => (
                                <div onClick={() => onSuggestedPromptClick(prompt)} key={index} className="suggestion-button">
                                    {prompt}
                                </div>
                            ))}
                        </div>
                    </div>
            }

            

            {props.hideHotTipContainer && !props.hideSuggestedPrompts && <div className="lightning-instance-parent">
                {<div className="more-suggestions-title" style={{textAlign: "left", paddingLeft: "5px"}}>How can I help?</div>}
                <div className="lightning-instance tooltip-5">
                    {suggestedPrompts.map((prompt, index) => (
                        <div onClick={() => onSuggestedPromptClick(prompt)} key={index} className="suggestion-button">
                            {prompt}
                        </div>
                    ))}
                </div>
            </div>}
            
            <div className="button-instance-container">
                <div className="button-instance-inner tooltip-6">
                    <div className="lightning-parent">
                        <img
                            className="lightning-icon"
                            loading="lazy"
                            alt=""
                            src="/lightning.svg"
                        />
                        <textarea className="addy-prompt-input"
                            style={{height: `${linesCount}em`}}
                            placeholder={props.placeholder || "Ask me anything..."}
                            value={textareaValue}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange} />

                        <img
                            data-toggle="tooltip"
                            title="Send message"
                            className="button-icon"
                            loading="lazy"
                            alt="Go"
                            onClick={() => onClick()}
                            // Change the source based on current value of textarea
                            src={(textareaValue && !currentlyTyping) ? "/enabled-button@2x.png" : "/button@2x.png"}
                        />
                    </div>
                </div>

                {!props.hideFeedbackRequestText && <div className="selected-text feedback-request-text">
                    <a href="https://forms.gle/z5mJsnJKNGiX7SGt8" target="_blank">Give us feedback</a> to help improve Addy AI
                </div>}
            </div>
        </div>
    );
};

export default ButtonInstance;
