import React from 'react';
import styles from "./ActionButton.module.css";

const ActionButton = (props) => {
    // console.log("Props:", props);

    const handleSelectChange = (event) => {
        const selectedOption = props.dropDownOptions.find(
            option => option[props.dropDownOptionsDisplayAttribute] === event.target.value
        );
        props.onDropDownChange(selectedOption, props.dropDownId);
    };

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    return (
        <div className={styles.button} onClick={props.onClick}>
            <div className={styles.suggestionDetails}>
                <div className={styles.suggestAReply}>
                    {props.suggestAReply}
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <div className={styles.toThisEmail}>{props.toThisEmail}</div>

                    {props.dropDownOptions && <div>
                        <select className={styles.selectDropdown} onChange={handleSelectChange} onClick={stopPropagation}>
                            {props.dropDownOptions.map((option, index) => 
                                <option key={index} value={option[props.dropDownOptionsDisplayAttribute]}>
                                    {option[props.dropDownOptionsDisplayAttribute]}
                                </option>
                            )}
                        </select>
                        </div>
                    }
                </div>

            </div>
            {props.showExpandIcon && <img className={styles.expandIcon} alt="" src="/expand.svg" />}
        </div>
    );
};

export default ActionButton;
