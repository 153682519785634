import {useMemo, forwardRef} from "react";
import "./FRAME.css";

const FRAME = forwardRef(({expand, showFRAME,
    propMargin, propBorderRadius, onClick, selectedAssistant, title}, ref) => {
    const selectedItem = selectedAssistant;
    if (selectedItem && selectedItem.appName) {
        selectedItem["name"] = selectedItem.appName;
    }
    if (selectedItem && selectedItem.appTitle) {
        selectedItem["title"] = selectedItem.appTitle;
    }

    const h2HeadingStyle = useMemo(() => {
        return {
            margin: propMargin,
            fontSize: "22px"

        };
    }, [propMargin]);

    const buttonStyle = useMemo(() => {
        return {
            borderRadius: propBorderRadius,
        };
    }, [propBorderRadius]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        showFRAME && (
            <div className="f-r-a-m-e7">
                {
                    title && (<h2 className="h2-heading46" style={h2HeadingStyle}>
                        {title}
                    </h2>)
                }
                <div onClick={onClick} ref={ref} className="button6" style={buttonStyle}>
                    <div className="expand-instance">
                        <div className="addy-assistant5">
                            {
                                selectedItem && selectedItem.name ?
                                    (selectedItem.name.length > 41 ?
                                        `${capitalizeFirstLetter(
                                            selectedItem.name.substring(
                                                0, 39))}...` :
                                        capitalizeFirstLetter(selectedItem.name)) :
                                    "Loading..."
                            }

                        </div>
                        {selectedItem && selectedItem.title && (<div className="regular-addy-ai1">
                            {selectedItem && selectedItem.title ?
                                selectedItem.title : "Loading..."}
                        </div>)}
                    </div>
                    <img className="expand-icon5" alt="" src={expand}
                        data-toggle="tooltip"
                        title="Choose assistant" />
                </div>
            </div>
        )
    );
});

export default FRAME;
