/* eslint-disable max-len */
const GlobalVariables = require("./globalVariables"); // Import the necessary module

class ChatModel {
    static getEndpoints() {
        return {
            chat: "/chat/chat/chat",
        };
    }

    static async chat(email, name, emailThread, selectedTable, attachmentListBase64, dealsPipeline) {
        const ENDPOINT = GlobalVariables.getAPIURL() + ChatModel.getEndpoints().chat;
        // eslint-disable-next-line max-len
        console.log("In chat model", email, name, emailThread, selectedTable, attachmentListBase64, dealsPipeline);
        return await fetch(`${ENDPOINT}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name,
                emailThread: emailThread,
                selectedTable: selectedTable,
                attachmentList: attachmentListBase64,
                dealsPipeline: dealsPipeline,
                isExtractingLoanDetails: true,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Data in chat model", data);
                if (data.success) {
                    console.log("Chat response", data.chatResponse);
                    return {success: true, chatResponse: data.chatResponse};
                } else {
                    console.log("Error in chat model", data.reason);
                    return {success: false, reason: "internal server error"};
                }
            })
            .catch((error) => {
                console.error(error);
                return {success: false, reason: "internal server error"};
            });
    }
}

module.exports = ChatModel;
