import styles from "./EmailActionNavigationBreadCrumbs.module.css";
import {useEffect, useState} from "react";

const EmailActionNavigationBreadCrumbs = (props) => {
    const [currentEmailAction, setCurrentEmailAction] = useState("");

    useEffect(() => {
        setCurrentEmailAction(props?.currentEmailAction || "");
        // console.log("Props:", props);
    }, [props]);

    // When current email action changes
    useEffect(() => {
        // console.log("Current Email Action", currentEmailAction);
        const container = document.getElementById("bread-crumbs-child-container");
        if (!currentEmailAction) {
            if (container && container.parentElement) container.parentElement.style.display = "none";
        } else {
            if (container && container.parentElement) container.parentElement.style.display = "flex";
        }
    }, [currentEmailAction]);

    const onHomeClick = () => {
        setCurrentEmailAction("");
        props.breadCrumbsHomeClick();
    };

    return (

        <div id="bread-crumbs-child-container" className={styles.breadCrumbsNavigationContainer}>
            <img className={styles.homeIcon}
                src={currentEmailAction ? "/home-stroke-rounded.svg" : "/home-solid-rounded.svg"}
                width="12" height="12" alt="Home"
                onClick={() => onHomeClick()}/>
            {currentEmailAction && currentEmailAction.length > 0 &&
                <div className={styles.currentBreadCrumb1Container}>
                    <img src="/arrow-right-stroke-rounded.svg" width="12" height="12" alt=""/>
                    <div className={styles.breadCrumbText}>
                        {currentEmailAction}</div>
                </div>
            }

        </div>
    );
};

export default EmailActionNavigationBreadCrumbs;
