/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable space-before-function-paren */
import {useState, useEffect, useRef} from "react";
import {
    Autocomplete,
    Select,
    InputLabel,
    MenuItem,
    FormHelperText,
    FormControl,
    // InputAdornment,
    Button,
    TextField,
} from "@mui/material";
// import OpenExternalSiteButton from "../components/OpenExternalSiteButton";
import styles from "./Form.module.css";
import ConfidenceLevel from "../components/ConfidenceLevel";
import Tooltip from "../components/Tooltip";
import FRAME from "../components/FRAME";
import ChatAssistantDropdown2 from "./ChatAssistantDropdown2";
import Hubspot from "../models/hubspot";
import ChatModel from "../models/chatModel";
import User from "../models/user";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useLocalStorage from "../hooks/localStorage";
import * as GlobalVariables from "../models/globalVariables";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import AddyWebSocket from "../models/addyWebSocket";
import {useLocation, useNavigate} from "react-router-dom";
import Salesforce from "../models/salesforce";

const Form = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tableMapping, setTableMapping] = useLocalStorage("tableMapping", "");
    const [selectedIntegrationStorage, setSelectedIntegrationStorage] =
      useLocalStorage("crmIntegration", {});

    const urlParams = new URLSearchParams(window.location.search);

    const tableDropDownRef = useRef(null);
    const tableDropdownListRef = useRef(null);
    // User data passed from chrome extension

    let data = {};
    let emailThread = "";
    let attachmentListBase64 = [];
    if (location?.state?.data) {
        data = location.state.data;
    }
    if (location?.state?.emailThread) {
        emailThread = location.state.emailThread;
    }
    if (location?.state?.attachmentListBase64) {
        attachmentListBase64 = location.state.attachmentListBase64;
    }
    const uid = data?.uid || null;
    const email = data?.email || null;
    // const token = data?.token || null;
    const clientID = data?.clientID || null;

    // const uid = urlParams.get("uid");
    // const email = urlParams.get("email");
    // const token = urlParams.get("token");
    const name = data?.name || "You";
    // const clientID = urlParams.get("clientID");
    // const clientHostname = urlParams.get("clientHostname");

    const sampleIntegration = "HubSpot";
    // const sampleIntegrationEmail = "michael@addy-ai.com";
    // const sampleCRMColumnHeaders = ["First Name", "Last Name", "Email", "Phone", "Broker Type", "Company's Name", "Requested Loan Amount", "Property Location", "Loan Urgency"];


    const [loading, setLoading] = useState(true);
    const [loadingAddToHubspot, setLoadingAddToHubspot] = useState(false);
    const [loadingConfirmData, setLoadingConfirmData] = useState(false);
    const [columnHeadersFromCRM, setColumnHeadersFromCRM] = useState([]);
    const [fullColumenHeadersFromCRM, setFullColumenHeadersFromCRM] = useState([]);
    // Step 1 = confirm data, step 2 = map column headers, step 3 = congrats scren
    const [selectedCRMTable, setSelectedCRMTable] = useState({});
    const [step, setStep] = useState(1);
    // const [newData, setNewData] = useState();
    const [connectToHubspotNeeded, setConnectToHubspotNeeded] = useState(false);
    const [connectToSalesforceNeeded, setConnectToSalesforceNeeded] = useState(false);

    const [errorGettingData, setErrorGettingData] = useState(false);

    const windowListenForPostMessage = () => {
        window.addEventListener("message", (event) => {
            const origin = event.origin || event.originalEvent.origin;
            // console.log("Origin", origin);

            const data = event.data;

            if (data && data.key) {
                const action = data.key;
                const value = data.value;
                switch (action) {
                    case "emailThread":
                        emailThread = value;
                        break;
                    case "attachmentListBase64":
                        attachmentListBase64 = value;
                        break;
                    default:
                        break;
                }
            }
        });
    };


    async function callLLM(tableV, first, sampleRenderDataContacts, sampleRenderDataDeals = [], dealsPipeline = [], crmIntegrationF) {
        try {
            if (first) setLoading(true);
            const response = await ChatModel.chat(email, name, emailThread, tableV, attachmentListBase64, dealsPipeline);

            console.log("response", response.chatResponse);
            console.log("sampleRenderDataContacts", sampleRenderDataContacts);
            console.log("sampleRenderDataDeals", sampleRenderDataDeals);

            if (response && response.success && response.chatResponse) {
                for (let i = 0; i < sampleRenderDataContacts.length; i++) {
                    const confidence = "confidence" + sampleRenderDataContacts[i].attribute.charAt(0).toUpperCase() + sampleRenderDataContacts[i].attribute.slice(1);
                    sampleRenderDataContacts[i].confidence = response.chatResponse[confidence];
                    if (sampleRenderDataContacts[i].type === "dropdown") {
                        sampleRenderDataContacts[i].selectedIndex = sampleRenderDataContacts[i].value.indexOf(response.chatResponse[sampleRenderDataContacts[i].attribute]);
                    } else if (sampleRenderDataContacts[i].type === "singleLineText") {
                        sampleRenderDataContacts[i].value = response.chatResponse[sampleRenderDataContacts[i].attribute];
                    }
                }
                for (let i = 0; i < sampleRenderDataDeals.length; i++) {
                    const confidence = "confidence" + sampleRenderDataDeals[i].attribute.charAt(0).toUpperCase() + sampleRenderDataDeals[i].attribute.slice(1);
                    sampleRenderDataDeals[i].confidence = response.chatResponse[confidence];
                    if (sampleRenderDataDeals[i].type === "dropdown") {
                        if (sampleRenderDataDeals[i].attribute === "dealPipeline") {
                            const labelArray = dealsPipeline.dealsPipelines.map(
                                (item) => item.label,
                            );
                            sampleRenderDataDeals[i].value = labelArray;
                            sampleRenderDataDeals[i].selectedIndex = labelArray.indexOf(response.chatResponse[sampleRenderDataDeals[i].attribute]);
                        } else {
                            if (sampleRenderDataDeals[i].attribute === "dealStage" && crmIntegrationF === "hubspot") {
                                const stageNamesArray = [];
                                dealsPipeline.dealsPipelines.forEach((item) => {
                                    item.stages.forEach((stage) =>
                                        stageNamesArray.push(stage.label,
                                        ));
                                });
                                sampleRenderDataDeals[i].value = stageNamesArray;
                                sampleRenderDataDeals[i].selectedIndex = stageNamesArray.indexOf(response.chatResponse[sampleRenderDataDeals[i].attribute]);
                            } else {
                                sampleRenderDataDeals[i].selectedIndex = sampleRenderDataDeals[i].value.indexOf(response.chatResponse[sampleRenderDataDeals[i].attribute]);
                            }
                        }
                    } else if (sampleRenderDataDeals[i].type === "singleLineText") {
                        sampleRenderDataDeals[i].value = response.chatResponse[sampleRenderDataDeals[i].attribute];
                    }
                }

                // const renderDataTemp = renderData;
                // renderDataTemp["contacts"] = sampleRenderDataContacts;
                // renderDataTemp["deals"] = sampleRenderDataDeals;
                // setRenderData(renderDataTemp);

                await populateUsersFromDomain(
                    sampleRenderDataContacts,
                    sampleRenderDataDeals,
                    renderData,
                    first,
                    tableV,
                );
                

                setErrorGettingData(false);
            } else {
                console.log("Error getting data from LLM");
                setErrorGettingData(true);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setErrorGettingData(true);
        }
    }

    async function populateUsersFromDomain(sampleRenderDataContacts, sampleRenderDataDeals, renderDataTemp, first, table) {
        if (table !== "opportunities") {
            const userDomains = await getUsersByDomain();
            if (userDomains.length > 0) {
                const userMails = userDomains.map((user) => user.email);
                const userNames = userDomains.map((user) => user.name);

                sampleRenderDataContacts[sampleRenderDataContacts.length - 1].confidence = 10;
                sampleRenderDataContacts[sampleRenderDataContacts.length - 1].value = userNames;
                sampleRenderDataContacts[sampleRenderDataContacts.length - 1].selectedIndex = userMails.indexOf(email);

                sampleRenderDataDeals[sampleRenderDataDeals.length - 1].confidence = 10;
                sampleRenderDataDeals[sampleRenderDataDeals.length - 1].value = userNames;
                sampleRenderDataDeals[sampleRenderDataDeals.length - 1].selectedIndex = userMails.indexOf(email);
            }
            console.log("sampleRenderDataContacts", sampleRenderDataContacts);
            renderDataTemp["contacts"] = sampleRenderDataContacts;
            renderDataTemp["deals"] = sampleRenderDataDeals;
            setRenderData(renderDataTemp);
            if (first) {
              initializeFormData(sampleRenderDataContacts);
            }
        } else {
            renderDataTemp["opportunities"] = sampleRenderDataDeals;
            renderDataTemp["contacts"] = sampleRenderDataContacts; 

            // renderDataTemp["contacts"] = await populateContactsFromSalesforce(
            //     GlobalVariables.getCorrectRenderData(
            //         "contacts-salesforce"
            //     ) 
            // );

            setRenderData(renderDataTemp);
            if (first) {
              initializeFormData(sampleRenderDataDeals);
            }
        }
    }


    async function populateView(integration) {
        try {
            if (integration.toLowerCase() === "hubspot") {
              await populateFromHubspot();
            } else {
              await populateFromSalesforce();
            }
        } catch (error) {
            console.error(error);
            setConnectToHubspotNeeded(true);
        }
    }

    const [renderData, setRenderData] = useState([]);
    const [formData, setFormData] = useState({});
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [integration, setIntegration] = useState([]);
    const [integrationEmail, setIntegrationEmail] = useState("");
    const [showConfidenceScoreTooltip, setShowConfidenceScoreTooltip] = useState(false);
    const [showTableDropDown, setShowTableDropDown] = useState(false);
    const [tables, setTables] = useState([]);
    const [defaultTable, setDefaultTable] = useState({});
    const [tableReferencedElementId, setTableReferencedElementId] = useState("");
    const [showTableUsersDropDown, setShowTableUsersDropDown] = useState(false);
    const [usersFromDomain, setUsersFromDomain] = useState([]);
    const [defaultUsersFromDomain, setDefaultUsersFromDomain] = useState({});
    const [selectedUsersFromDomain, setSelectedUsersFromDomain] = useState({});

    const [addedUrls, setAddedUrls] = useState([]);
    const [currentlyFocussedFields, setCurrentlyFocussedFields] = useState([]);
    // const [CRMColumnHeaderMap, setCRMColumnHeaderMap] = useState({});


    const [selectedCRMColumnHeader, setSelectedCRMColumnHeader] = useState([{}]);
    const [selectedCRMColumnHeadersList, setSelectedCRMColumnHeadersList] = useState([]);

    // Add this function inside your component
    const handleChange = (attribute, type) => (event) => {
        if (type === "singleLineText" || type === "dropdown") {
            setFormData((prev) => ({
                ...prev,
                [attribute]: event.target.value,
            }));
            let renderDataTemp = renderData[selectedCRMTable.name];
            if(renderDataTemp && renderDataTemp.length > 0) {
                renderDataTemp = renderDataTemp.map((field) => {
                    if (field.attribute === attribute) {
                        if (type === "dropdown") {
                            field.selectedIndex = field.value.indexOf(event.target.value);
                        } else {
                            field.value = event.target.value;
                        }
                    }
                    return field;
                });
            }
            setRenderData((prev) => ({  ...prev, [selectedCRMTable.name]: renderDataTemp }));
        }
    };

    const clearHighlightedText = (id) => {
        // Send clear action to client webpage via WebSocket
        if (id) {
            AddyWebSocket.retrySendDataToWebsocket("", `${id}-webpage`, "clear-all-highlighted-text", 2);
        }
    };

    const highlightTextOnClient = (id, text) => {
        // Send highlight action to client webpage via WebSocket
        if (id) {
            AddyWebSocket.retrySendDataToWebsocket(text, `${id}-webpage`, "highlight-selected-field", 2);
        }
    };


    // When user changes the CRM column header
    // const handleCRMColumnHeaderChange = (newValue, attribute) => (event) => {
    //     console.log("event", newValue);

    //     console.log("event", attribute);
    //     setCRMColumnHeaderMap((prev) => ({
    //         ...prev,
    //         [attribute]: event.target.value,
    //     }));
    // };
    useEffect(() => {
        windowListenForPostMessage();
        getUserData();

        // eslint-disable-next-line no-undef
        document.addEventListener("click", handleClickOutside);
        return () => {
            // eslint-disable-next-line no-undef
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // Use effect on focussed fields change
    useEffect(() => {
        // If there are no focussed fields, clear all highlighted text
        if (currentlyFocussedFields.length === 0) {
            clearHighlightedText(clientID);
        } else {
            // If there are focussed fields, highlight the text
            for (const field of currentlyFocussedFields) {
                highlightTextOnClient(clientID, field.value);
            }
        }
    }, [currentlyFocussedFields]);

    const getUserData = async () => {
        const userInfo = await User.getUserInfo(email);
        let integrations = [];
        if (userInfo && userInfo.success && userInfo.response) {
            if(userInfo.response.hubspot) {
                integrations.push({integration: GlobalVariables.integrationType.HUBSPOT, email: email});
            }
            if(userInfo.response.salesforce) {
                integrations.push({integration: GlobalVariables.integrationType.SALESFORCE, email: email});
            }
        }

        setIntegration(integrations);
        if (integrations.length >= 1) {
          if (
            selectedIntegrationStorage &&
            selectedIntegrationStorage.integration &&
            integrations.find(
              (integration) =>
                integration.integration === selectedIntegrationStorage.integration
            )
          ) {
            setSelectedIntegration(selectedIntegrationStorage);
            populateView(selectedIntegrationStorage.integration);
          } else {
            setSelectedIntegration(integrations[0]);
            populateView(integrations[0].integration);
          }
          setIntegrationEmail(email);
          setConnectToHubspotNeeded(false);
        } else {
          setConnectToHubspotNeeded(true);
          setLoading(false)
        }
        
    }

    const initializeFormData = (renderData) => {
        for (const field of renderData) {
            let value = field.value;
            if (field.type === "dropdown") {
                value = field.value[field.selectedIndex];
            }
            setFormData((prev) => ({
                ...prev,
                [field.attribute]: value,
            }));
        }
    };

    const writeEmail = async () => {

        navigate("/email/home", {
            state: {
                data: data,
                emailThread: emailThread,
                attachmentListBase64: attachmentListBase64,
                writeLoan: true,
                uid: uid,
                loanInformation: renderData,
            },
        });
    };

    const handleFocus = (attribute, type, value) => (event) => {
        const focusedField = {
            "attribute": attribute,
            "type": type,
            "value": value,
        };
        // If this field is not already in the currently focussed fields array
        // Add it to the array
        if (!currentlyFocussedFields.find((field) => field.attribute === attribute)) {
            setCurrentlyFocussedFields((prev) => [...prev, focusedField]);
        }
    };

    // Remove the field from the currently focussed fields array
    const handleBlur = (attribute) => (event) => {
        // Remove the field from the currently focussed fields array
        setCurrentlyFocussedFields((prev) => prev.filter((field) => field.attribute !== attribute));
    };

    const renderInputField = (field) => {
        switch (field.type) {
            case "singleLineText":
                return (
                    <div className={styles.h2HeadingGroup}>
                        <div className={styles.h2Heading2}>{field.display}</div>

                        <div className={styles.scoreFrame}>
                            <div className={styles.textInputContainer}>
                                <input
                                    className={styles.textInput}
                                    value={formData[field.attribute]}
                                    onFocus={handleFocus(field.attribute, field.type, formData[field.attribute])}
                                    onBlur={handleBlur(field.attribute)}
                                    onChange={handleChange(field.attribute, field.type)}
                                    placeholder={field.placeholder || ""}
                                    type={ field.attribute === "closeDate" ? "date" : "text" }
                                />
                            </div>

                            <div>
                                <ConfidenceLevel score={field.confidence} />
                            </div>
                        </div>

                    </div>
                );
            case "dropdown":
                return (
                    <div className={styles.h2HeadingParent2}>
                        <div className={styles.dropDownInputContainer}>
                            <div className={styles.h2Heading2}>{field.display}</div>
                            <div className={styles.scoreFrame}>
                                <FormControl
                                    className={styles.button5}
                                    variant="standard"
                                    sx={{
                                        "borderColor": "#eff1f6",
                                        "borderStyle": "SOLID",
                                        "borderTopWidth": "1px",
                                        "borderRightWidth": "1px",
                                        "borderBottomWidth": "1px",
                                        "borderLeftWidth": "1px",
                                        "backgroundColor": "#fff",
                                        "borderRadius": "8px",
                                        "width": "100%",
                                        "height": "32px",
                                        "m": 0,
                                        "p": 0,
                                        "& .MuiInputBase-root": {
                                            m: 0,
                                            p: 0,
                                            minHeight: "32px",
                                            justifyContent: "center",
                                            display: "inline-flex",
                                        },
                                        "& .MuiInputLabel-root": {
                                            m: 0,
                                            p: 0,
                                            minHeight: "32px",
                                            display: "inline-flex",
                                        },
                                        "& .MuiMenuItem-root": {
                                            m: 0,
                                            p: 0,
                                            height: "32px",
                                            display: "inline-flex",
                                        },
                                        "& .MuiSelect-select": {
                                            m: 0,
                                            p: 0,
                                            height: "32px",
                                            alignItems: "center",
                                            display: "inline-flex",
                                        },
                                        "& .MuiInput-input": {m: 0, p: 0},
                                        "& .MuiInputBase-input": {
                                            color: "#0d0d25",
                                            fontSize: 14,
                                            fontWeight: "Medium",
                                            fontFamily: "Inter",
                                            textAlign: "left",
                                            p: "0 !important",
                                            marginLeft: "12px",
                                        },
                                    }}
                                >
                                    <InputLabel color="secondary" />
                                    <Select
                                        color="secondary"
                                        disableUnderline
                                        displayEmpty

                                        defaultValue={formData[field.attribute]}
                                        value={formData[field.attribute]}
                                        onChange={handleChange(field.attribute, field.type)}
                                    >
                                        {field.value.map((option, index) => (
                                            <MenuItem key={index} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText />
                                </FormControl>

                                <div>
                                    <ConfidenceLevel score={field.confidence} />
                                </div>
                            </div>

                        </div>

                    </div>
                );
            default:
                return null;
        }
    };


    const renderMapColumnHeaderPage = () => {
        const renderMapRow = (field, index) => {
            return (
                <div className={styles.h2HeadingGroup}>
                    <div className={styles.mapColumnRow}>
                        <div className={styles.mapColumnFieldName}>{field.display}</div>

                        <div className={styles.mapColumnHeaderDropDown}>
                            <FormControl
                                className={styles.button5}
                                variant="standard"
                                sx={{
                                    "borderColor": "#eff1f6",
                                    "borderStyle": "SOLID",
                                    "borderTopWidth": "1px",
                                    "borderRightWidth": "1px",
                                    "borderBottomWidth": "1px",
                                    "borderLeftWidth": "1px",
                                    "backgroundColor": "#fff",
                                    "borderRadius": "8px",
                                    "width": "100%",
                                    "height": "32px",
                                    "m": 0,
                                    "p": 0,
                                    "& .MuiInputBase-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "32px",
                                        justifyContent: "center",
                                        display: "inline-flex",
                                    },
                                    "& .MuiInputLabel-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "32px",
                                        display: "inline-flex",
                                    },
                                    "& .MuiMenuItem-root": {
                                        m: 0,
                                        p: 0,
                                        height: "32px",
                                        display: "inline-flex",
                                    },
                                    "& .MuiSelect-select": {
                                        m: 0,
                                        p: 0,
                                        height: "32px",
                                        alignItems: "center",
                                        display: "inline-flex",
                                    },
                                    "& .MuiInput-input": {m: 0, p: 0},
                                    "& .MuiInputBase-input": {
                                        color: "#0d0d25",
                                        fontSize: 14,
                                        fontWeight: "Medium",
                                        fontFamily: "Inter",
                                        textAlign: "left",
                                        p: "0 !important",
                                        marginLeft: "12px",
                                    },
                                }}
                            >
                                <InputLabel color="secondary" />
                                <Autocomplete
                                    color="secondary"
                                    disableUnderline
                                    displayEmpty
                                    defaultValue={columnHeadersFromCRM?.filter((option) => option.type === "N/A")[0]}
                                    value={selectedCRMColumnHeader[field.attribute]}
                                    onChange={(event, newValue) => {
                                        if (selectedIntegration.integration === GlobalVariables.integrationType.HUBSPOT){
                                            setSelectedCRMColumnHeadersList((prev) => ({
                                                ...prev,
                                                [field.attribute]: {
                                                    hubspotValue: newValue,
                                                    variableValue: newValue === null ? undefined : newValue.type === "N/A" ? undefined : formData[field.attribute],
                                                    table: selectedCRMTable.name,
                                                },
                                            }));
                                        } else {
                                            setSelectedCRMColumnHeadersList((prev) => ({
                                                ...prev,
                                                [field.attribute]: {
                                                    salesforceValue: newValue,
                                                    variableValue: newValue === null ? undefined : newValue.type === "N/A" ? undefined : formData[field.attribute],
                                                    table: selectedCRMTable.name,
                                                },
                                            }));
                                        }
                                        // handleCRMColumnHeaderChange(newValue, field.attribute);
                                        setSelectedCRMColumnHeader((prev) => ({
                                            ...prev,
                                            [field.attribute]: newValue === null ? {
                                                name: "Select a property",
                                                label: "Select a property",
                                                type: "N/A",
                                            } : newValue,
                                        }));
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    options={columnHeadersFromCRM}
                                    renderInput={(params) => <TextField key={params.label} {...params} label="Property" />
                                    }
                                />
                                <FormHelperText />
                            </FormControl>
                        </div>

                    </div>

                </div>
            );
        };
        return (
            <section className={styles.expandableInstanceWrapper}>


                <div className={styles.expandableInstance}>
                    {/* <OpenExternalSiteButton /> */}
                    <div className={styles.expandableInstanceInner}>
                        <form className={styles.frameParent}>
                            <div className={styles.frameGroup}>
                                <div className={styles.frameWrapper}>
                                    <div className={styles.h2HeadingParent}>
                                        <div className={styles.formHeaderDiv}>
                                            <b className={styles.h2Heading1}>Map columns to {selectedIntegration.integration} properties</b>
                                            <div className={styles.frameParentScore}></div>
                                        </div>

                                        <div className={styles.mapColumnTitleHeader}>
                                            <div className={styles.mapColumnFieldName}>
                                                Column Header
                                            </div>

                                            <div className={styles.mapColumnIntegrationPropertyHeader}>
                                                <div className={styles.mapColumnFieldName}>
                                                    {selectedIntegration.integration} Property
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            renderData[selectedCRMTable.name].map((field, index) => (
                                                <div className={styles.fieldContainer} key={index}>
                                                    {renderMapRow(field, index)}
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </section>
        );
    };

    const handleConfidenceTooltipCloseClick = () => {
        setShowConfidenceScoreTooltip(false);
    };

    const selectTableClick = (elemId, showTable) => {
        setTableReferencedElementId(elemId);
        setShowTableDropDown(!showTable);
    };

    async function populateFromSalesforce() {
        const response = { success: true, objects: ["opportunities", "contacts"] };
        let tableV = "Opportunities";
        if (response && response.success && response.objects.length > 0) {
            const tables = response.objects.map((table) => {
                return {
                    name: table,
                };
            });
            setTables(tables);
            tableV = tables[0].name;
            setSelectedCRMTable({
                name: tables[0].name,
                title: "Store data using this table", // use this static title
            });
            setDefaultTable(tables[0]);
            if (step === 2) {
                const response2 = await Salesforce.getObjectProperties(email, tables[0].name);
                if (response2 && response2.success && response2.properties.length > 0) {
                    response2.properties.push({
                        name: "Select a property",
                        label: "Select a property",
                        type: "N/A",
                    });
                    setColumnHeadersFromCRM(response2.properties);
                }
            }
        } else {
            setConnectToSalesforceNeeded(true);
        }

        while (emailThread === "") {
            await new Promise((r) => setTimeout(r, 200));
        }

        const sampleOppurtunityRenderDataDeals =
          GlobalVariables.getCorrectRenderData("opportunities");
        const sampleRenderDataContacts =
          GlobalVariables.getCorrectRenderData("contacts-salesforce");
          console.log("sampleRenderDataContacts", sampleRenderDataContacts);

        await callLLM(
          tableV,
          true,
          sampleRenderDataContacts,
          sampleOppurtunityRenderDataDeals,
          [],
          "salesforce"
        );
    }

    async function populateContactsFromSalesforce(sampleRenderDataContacts) {
        const emailRegex = /Sender's Email:\s*(\S+)/;
        const nameRegex = /Sender's Name:\s*(.+)/;

        const emailMatch = emailThread.match(emailRegex);
        const nameMatch = emailThread.match(nameRegex);

        const senderEmail = emailMatch ? emailMatch[1] : null;
        const senderName = nameMatch ? nameMatch[1] : null;

        if (!senderEmail || !senderName) {
            return sampleRenderDataContacts;
        }

        // if (senderName.includes(" ")) {
        //     sampleRenderDataContacts[0].value = senderName.split(" ")[0];
        //     sampleRenderDataContacts[1].value = senderName.split(" ")[1];
        // } else {
        //     sampleRenderDataContacts[0].value = "";
        //     sampleRenderDataContacts[1].value = senderName;
        // }


        sampleRenderDataContacts[2].value = senderEmail;

        return sampleRenderDataContacts;
    }

    async function populateFromHubspot() {
        const response = {success: true, objects: ["contacts", "deals"]};
        let tableV = "Contacts";
        if (response && response.success && response.objects.length > 0) {
            const tables = response.objects.map((table) => {
                return {
                    name: table,
                };
            });
            GlobalVariables.getCorrectRenderData(tables[0].name);
            setTables(tables);
            tableV = tables[0].name;
            setSelectedCRMTable({
                name: tables[0].name,
                title: "Store data using this table", // use this static title
            });
            setDefaultTable(tables[0]);
            if (step === 2) {
                const response2 = await Hubspot.getObjectProperties(email, tables[0].name);
                if (response2 && response2.success && response2.properties.length > 0) {
                    response2.properties.push({
                        name: "Select a property",
                        label: "Select a property",
                        type: "N/A",
                    });
                    setColumnHeadersFromCRM(response2.properties);
                }
            }
        } else {
            setConnectToHubspotNeeded(true);
        }

        const dealsPipeline = await Hubspot.getAllDealsPipelines(email);

        while (emailThread === "") {
            await new Promise((r) => setTimeout(r, 200));
        }

        const sampleRenderDataContacts = GlobalVariables.getCorrectRenderData("contacts");
        const sampleRenderDataDeals = GlobalVariables.getCorrectRenderData("deals");

        await callLLM(tableV, true, sampleRenderDataContacts, sampleRenderDataDeals, dealsPipeline, "hubspot");
    }

    async function getUsersByDomain() {
        const domain = email.split("@")[1];
        let userDomains = [];
        if (domain !== "gmail.com") {
            userDomains = await User.getUsersByDomain(domain);
            if (userDomains && userDomains.success) {
                userDomains = userDomains.response.map((user) => {
                    return {email: user.email, name: user.name !== undefined ? user.name : user.userData.name !== undefined ? user.userData.name : user.email};
                });

                setUsersFromDomain(userDomains);
                const user = userDomains.filter((user) => user.email === email);
                if (user.length > 0) {
                    setDefaultUsersFromDomain(user);
                    setSelectedUsersFromDomain(user);
                } else {
                    setDefaultUsersFromDomain(userDomains[0]);
                    setSelectedUsersFromDomain(userDomains[0]);
                }
            }
        }
        return userDomains;
    }

    async function onTableClick(table) {
        if(loading) {
          setShowTableDropDown(false);
        }        
        if (table.name === "contacts") {
            setColumnHeadersFromCRM(fullColumenHeadersFromCRM.Contact);
        } else {
          if(table.name === "deals") {
            setColumnHeadersFromCRM(fullColumenHeadersFromCRM.Deal);
          } else {
            setColumnHeadersFromCRM(fullColumenHeadersFromCRM.Opportunity);
          }
        }
        setTableMapping(selectedCRMColumnHeadersList);
        initializeFormData(renderData[table.name]);
        setSelectedCRMTable({
            name: table.name,
            title: "Store data using this table", // use this static title
        });
        setShowTableDropDown(false);
    }

    const addToHubspotClick = async () => {
        const hubspotValuesToUpdate = {};
        for (let i = 0; i < Object.keys(selectedCRMColumnHeadersList).length; i++) {
            const header = Object.keys(selectedCRMColumnHeadersList)[i];
            const value = selectedCRMColumnHeadersList[header];
            if (hubspotValuesToUpdate[value.table] === undefined) {
                hubspotValuesToUpdate[value.table] = {};
            }
            if (value && value.hubspotValue && value.hubspotValue.name) {
                if (value.variableValue !== undefined) {
                    hubspotValuesToUpdate[value.table][value.hubspotValue.name] =
                        value.hubspotValue.name === "dealstage" ? value.variableValue.replaceAll(" ", "").toLowerCase() : value.variableValue;
                } else {
                    hubspotValuesToUpdate[value.table][value.hubspotValue.name] =
                        value.hubspotValue.name === "dealstage" ? formData[header].replaceAll(" ", "").toLowerCase() : formData[header];
                }
            }
        }
        return await Hubspot.addToHubspot(email, hubspotValuesToUpdate);
    };

    const addToSalesforceClick = async () => {
        const salesforceValuesToUpdate = {};
        for (let i = 0; i < Object.keys(selectedCRMColumnHeadersList).length; i++) {
            const header = Object.keys(selectedCRMColumnHeadersList)[i];
            const value = selectedCRMColumnHeadersList[header];
            if (salesforceValuesToUpdate[value.table] === undefined) {
              salesforceValuesToUpdate[value.table] = {};
            }
            if (value && value.salesforceValue && value.salesforceValue.name) {
                if (value.variableValue !== undefined) {
                    salesforceValuesToUpdate[value.table][value.salesforceValue.name] =
                        value.salesforceValue.name === "dealstage" ? value.variableValue.replaceAll(" ", "").toLowerCase() : value.variableValue;
                } else {
                    salesforceValuesToUpdate[value.table][value.salesforceValue.name] =
                        value.salesforceValue.name === "dealstage" ? formData[header].replaceAll(" ", "").toLowerCase() : formData[header];
                }
            }
        }
        return await Salesforce.addToSalesforce(email, salesforceValuesToUpdate);
        
    }

    const updateDefaultTable = (table, e) => {
        if (!e) return;
        if (!table) return;
        setDefaultTable(table);
        // TODO: Not now but in phase 2, set the default table in the backend
        return;
    };

    // Event handler for click events
    const handleClickOutside = (event) => {
        // If user clicks outside or inside of dropdown ref
        // Close the dropdown
        if (tableDropDownRef.current &&
            !tableDropDownRef.current.contains(event.target) &&
            tableDropdownListRef.current && !tableDropdownListRef.current.contains(event.target)) {
            setShowTableDropDown(false);
            if (!(tableDropdownListRef.current &&
                !tableDropdownListRef.current.contains(event.target))) {
                setShowTableDropDown(true);
            }
        }
    };

    // Event handler for when user clicks on the confirm data button
    const confirmDataClick = async (table, selectedIntegration) => {
        if(selectedIntegration === GlobalVariables.integrationType.HUBSPOT) {
            setLoadingConfirmData(true);
            const response2 = await Hubspot.getObjectProperties(email, table.name);
            // const sample = GlobalVariables.getCorrectRenderData(table.name);
            if (response2 && response2.success && response2.properties) {
                response2.properties.Contact.push({
                    name: "Select a property",
                    label: "Select a property",
                    type: "N/A",
                });
                response2.properties.Deal.push({
                    name: "Select a property",
                    label: "Select a property",
                    type: "N/A",
                });
                setFullColumenHeadersFromCRM(response2.properties);
                if(table === "contacts") {
                    setColumnHeadersFromCRM(response2.properties.Contact);
                } else {
                    setColumnHeadersFromCRM(response2.properties.Deal);
                }
            
                initializeFormData(renderData["contacts"]);
                initializeFormData(renderData["deals"]);
            }
            // eslint-disable-next-line guard-for-in
            for (const key in tableMapping) {
                setSelectedCRMColumnHeadersList((prev) => ({
                    ...prev,
                    [key]: {
                        hubspotValue: tableMapping[key]["hubspotValue"],
                        variableValue: formData[key],
                        table: tableMapping[key]["table"],
                    },
                }));

                setSelectedCRMColumnHeader((prev) => ({
                    ...prev,
                    [key]: tableMapping[key]["hubspotValue"],
                }));
            }

            // Move to next step to map CRM column headers
            setStep(step + 1);
            setLoadingConfirmData(false);
        } else {
            setLoadingConfirmData(true);
            
            const response2 = await Salesforce.getObjectProperties(email, table.name);
            // const sample = GlobalVariables.getCorrectRenderData(table.name);
            if (response2 && response2.success && response2.properties) {
                response2.properties.Contact.push({
                    name: "Select a property",
                    label: "Select a property",
                    type: "N/A",
                });
                response2.properties.Opportunity.push({
                    name: "Select a property",
                    label: "Select a property",
                    type: "N/A",
                });
                setFullColumenHeadersFromCRM(response2.properties);
                if(table === "contacts") {
                    setColumnHeadersFromCRM(response2.properties.Contact);
                } else {
                    setColumnHeadersFromCRM(response2.properties.Opportunity);
                }
            
                initializeFormData(renderData["contacts"]);
                initializeFormData(renderData["opportunities"]);
            }
            // eslint-disable-next-line guard-for-in
            for (const key in tableMapping) {
                setSelectedCRMColumnHeadersList((prev) => ({
                    ...prev,
                    [key]: {
                        salesforceValue: tableMapping[key]["salesforceValue"],
                        variableValue: formData[key],
                        table: tableMapping[key]["table"],
                    },
                }));

                setSelectedCRMColumnHeader((prev) => ({
                    ...prev,
                    [key]: tableMapping[key]["salesforceValue"],
                }));
            }


            setStep(step + 1);
            // setStep(3);
            // await onAddToIntegrationClick(selectedIntegration);
            setLoadingConfirmData(false);
        }
    };

    const stepBackClick = () => {
        setStep(step - 1);
    };

    const onAddToIntegrationClick = async (selectedIntegration) => {
        if(selectedIntegration === GlobalVariables.integrationType.HUBSPOT) {
            setLoadingAddToHubspot(true);
            const response = await addToHubspotClick();

            if (response.success) {
                const ColumnHeadersList = selectedCRMColumnHeadersList;
                for (const key in ColumnHeadersList) {
                    if (Object.prototype.hasOwnProperty.call(ColumnHeadersList[key], "variableValue")) {
                        delete ColumnHeadersList[key].variableValue;
                    }
                }
                const urls = [];
                // eslint-disable-next-line guard-for-in
                for (const key in response.properties) {
                    urls.push({type: response.properties[key].table, url: response.properties[key].url});
                }

                setAddedUrls(urls);
                setTableMapping(ColumnHeadersList);
                toastify("Successfully added to HubSpot", true);
                setStep(step + 1);
            } else {
                toastify("Error adding to HubSpot", false);
                console.log("Error adding to HubSpot");
            }
            setLoadingAddToHubspot(false);
        } else {
            setLoadingAddToHubspot(true);
            const response = await addToSalesforceClick();
            if (response.success) {
                const ColumnHeadersList = selectedCRMColumnHeadersList;
                for (const key in ColumnHeadersList) {
                    if (Object.prototype.hasOwnProperty.call(ColumnHeadersList[key], "variableValue")) {
                        delete ColumnHeadersList[key].variableValue;
                    }
                }
                const urls = [];
                // eslint-disable-next-line guard-for-in

                for (const key in response.urls) {
                  urls.push({
                    type: response.urls[key].table,
                    url: response.urls[key].url,
                  });
                }
                setAddedUrls(urls);
                setTableMapping(ColumnHeadersList);
                toastify("Successfully added to Salesforce", true);
                setStep(step + 1);
            } else {
                toastify("Error adding to Salesforce", false);
                console.log("Error adding to Salesforce");
            }
            setLoadingAddToHubspot(false);
            
        }
    };

    const toastify = (message, success) => {
        if (success) {
            toast.success(message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    return (
      <div className={styles.commercial}>
        <main className={styles.topBarNewMessageParent}>
          <header className={styles.topBarNewMessage}>
            <div className={styles.commercial1}>
              <div className={styles.commercialDesign}>
                <div className={styles.wrapperUnion}>
                  <img
                    className={styles.unionIcon}
                    loading="lazy"
                    alt=""
                    src="/union.svg"
                  />
                </div>
                <div className={styles.topBarNewMessage1}>
                  <b className={styles.h2Heading}>Addy</b>
                </div>
              </div>
            </div>
            <FormControl
              className={styles.button}
              variant="standard"
              sx={{
                borderColor: "#eff1f6",
                borderStyle: "SOLID",
                borderTopWidth: "1px",
                borderRightWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                width: "59.34065934065934%",
                height: "32px",
                m: 0,
                p: 0,
                "& .MuiInputBase-root": {
                  m: 0,
                  p: 0,
                  minHeight: "32px",
                  justifyContent: "center",
                  display: "inline-flex",
                },
                "& .MuiInputLabel-root": {
                  m: 0,
                  p: 0,
                  minHeight: "32px",
                  display: "inline-flex",
                },
                "& .MuiMenuItem-root": {
                  m: 0,
                  p: 0,
                  height: "32px",
                  display: "inline-flex",
                },
                "& .MuiSelect-select": {
                  m: 0,
                  p: 0,
                  height: "32px",
                  alignItems: "center",
                  display: "inline-flex",
                },
                "& .MuiInput-input": { m: 0, p: 0 },
                "& .MuiInputBase-input": {
                  fontSize: 14,
                  fontWeight: "Medium",
                  fontFamily: "Inter",
                  textAlign: "left",
                  p: "0 !important",
                  marginLeft: "12px",
                },
              }}
            >
              <InputLabel color="secondary" />
              {selectedIntegration && selectedIntegration.integration ? (
                <Select
                  color="secondary"
                  disableUnderline
                  displayEmpty
                  value={selectedIntegration.integration}
                >
                  {integration &&
                    integration.length >= 1 &&
                    integration.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.integration}
                        onClick={(event) => {
                          setStep(1);
                          setSelectedIntegration(option);
                          populateView(option.integration);
                          setSelectedIntegrationStorage(option);
                        }}
                      >
                        {option.integration}{" "}
                        <mark className={styles.integrationIdentifier}>
                          {option.email}
                        </mark>{" "}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <div className="dot"></div>
              )}

              <FormHelperText />
            </FormControl>
            <img className={styles.openInFull} alt="" src="/open-in-full.svg" />
            <div className={styles.closeWrapper}></div>
          </header>

          <div
            className={styles.formHeaderDiv}
            style={{
              maringTop: "-24px",
              marginRight: "14px",
              marginLeft: "14px",
              width: "calc(100% - 28px)",
            }}
          >
            <b className={styles.stepIndicator}>Step {step} of 3</b>
            <div className={styles.frameParentScore}>
              <div className={styles.frameGroupScore}>
                {step === 1 ? (
                  <AutorenewIcon onClick={() => window.location.reload()} />
                ) : (
                  <ChevronLeftIcon onClick={stepBackClick} />
                )}
              </div>
            </div>
          </div>

          {step !== 3 && (
            <div id="addy-table-selection-div">
              {connectToHubspotNeeded ? (
                <div className={styles.connectToHubspotText}>
                  {" "}
                  Connect to HubSpot or Salesforce on the extension to get CRM
                </div>
              ) : (
                <FRAME
                  selectedAssistant={selectedCRMTable}
                  ref={tableDropDownRef}
                  disabled={loading}
                  onClick={() => {
                    if (!loading) {
                      selectTableClick(
                        "addy-table-selection-div",
                        showTableDropDown
                      );
                    }
                  }}
                  expand="/expand.svg"
                  showFRAME
                  title=""
                />
              )}
            </div>
          )}

          {showTableDropDown && (
            <div className={styles.selectTableDiv}>
              <ChatAssistantDropdown2
                displayPosition="bottom"
                assistants={tables}
                selectedAssistant={selectedCRMTable}
                referencedElementId={tableReferencedElementId}
                defaultAssistant={defaultTable}
                onAssistantClick={onTableClick}
                updateDefaultAssistant={updateDefaultTable}
                hideTabs={true}
                createNewLink="https://hubspot.com"
                headerText="Tables"
                hideAvatar={true}
                ref={tableDropdownListRef}
              />
            </div>
          )}

          {/* {step !== 3 && selectedUsersFromDomain && selectedUsersFromDomain.length > 0 && <div className={styles.h2HeadingParent2}>
                    <div className={styles.formHeaderDiv} style={{
                        marginRight: "14px",
                        marginLeft: "14px",
                        width: "calc(100% - 28px)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}>
                        <div className={styles.h2HeadingAssigned}>Assigned Agent</div>
                        <div className={styles.scoreFrame}>
                            <FormControl
                                className={styles.button5}
                                variant="standard"
                                sx={{
                                    "borderColor": "#eff1f6",
                                    "borderStyle": "SOLID",
                                    "borderTopWidth": "1px",
                                    "borderRightWidth": "1px",
                                    "borderBottomWidth": "1px",
                                    "borderLeftWidth": "1px",
                                    "backgroundColor": "#fff",
                                    "borderRadius": "8px",
                                    "width": "100%",
                                    "height": "32px",
                                    "m": 0,
                                    "p": 0,
                                    "& .MuiInputBase-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "32px",
                                        justifyContent: "center",
                                        display: "inline-flex",
                                    },
                                    "& .MuiInputLabel-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "32px",
                                        display: "inline-flex",
                                    },
                                    "& .MuiMenuItem-root": {
                                        m: 0,
                                        p: 0,
                                        height: "32px",
                                        display: "inline-flex",
                                    },
                                    "& .MuiSelect-select": {
                                        m: 0,
                                        p: 0,
                                        height: "32px",
                                        alignItems: "center",
                                        display: "inline-flex",
                                    },
                                    "& .MuiInput-input": {m: 0, p: 0},
                                    "& .MuiInputBase-input": {
                                        color: "#0d0d25",
                                        fontSize: 14,
                                        fontWeight: "Medium",
                                        fontFamily: "Inter",
                                        textAlign: "left",
                                        p: "0 !important",
                                        marginLeft: "12px",
                                    },
                                }}
                            >
                                <InputLabel color="secondary" />
                                <Select
                                    color="secondary"
                                    disableUnderline
                                    displayEmpty

                                    defaultValue={selectedUsersFromDomain}
                                    value={selectedUsersFromDomain}
                                    onChange={(event) => setSelectedUsersFromDomain(event.target.value)}
                                >
                                    {usersFromDomain.map((option, index) => (
                                        <MenuItem key={index} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText />
                            </FormControl>
                        </div>

                    </div>

                </div>
                } */}

          {loading ? (
            <div className={styles.loadingText}>
              <div className="dot"></div>Loading...
            </div>
          ) : null}

          {errorGettingData ? (
            <div className={styles.loadingText}>
              <div></div>Error while getting data
            </div>
          ) : null}

          {step === 1 && !loading && !errorGettingData ? (
            <section className={styles.expandableInstanceWrapper}>
              <div className={styles.expandableInstance}>
                {/* <OpenExternalSiteButton /> */}
                <div className={styles.expandableInstanceInner}>
                  <form className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                      <div className={styles.frameWrapper}>
                        <div className={styles.h2HeadingParent}>
                          <div className={styles.formHeaderDiv}>
                            <b className={styles.h2Heading1}>
                              Loan Opportunity
                            </b>
                            <div className={styles.frameParentScore}>
                              <div
                                className={styles.frameGroupScore}
                                onClick={() =>
                                  setShowConfidenceScoreTooltip(true)
                                }
                              >
                                <div className={styles.h2HeadingWrapperScore}>
                                  <div className={styles.h2HeadingScore}>
                                    Score
                                  </div>
                                  {showConfidenceScoreTooltip && (
                                    <Tooltip
                                      tooltipCloseClick={
                                        handleConfidenceTooltipCloseClick
                                      }
                                    />
                                  )}
                                </div>
                                <img
                                  className={styles.infoIconScore}
                                  loading="lazy"
                                  alt=""
                                  src="/info.svg"
                                />
                              </div>
                            </div>
                          </div>

                          {renderData[selectedCRMTable.name]?.map(
                            (field, index) => (
                              <div
                                className={styles.fieldContainer}
                                key={index}
                              >
                                {renderInputField(field)}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          ) : null}

          {step === 2 && !loading ? renderMapColumnHeaderPage() : null}
          {step === 3 && !loading ? (
            <div className={styles.successContainer}>
              <b className={styles.h2Heading1}>Congratulations!</b>
              <div className={styles.descriptionText}>
                You've successfully added the data to{" "}
                {selectedIntegration.integration}.
              </div>
              <div className={styles.descriptionText}>Added objects:</div>
              <div className={styles.descriptionText}>
                {addedUrls &&
                  addedUrls.map((added, index) => (
                    <>
                      <a target={"_blank"} href={added.url} key={index}>
                        {" "}
                        {added.type}{" "}
                      </a>
                      <p></p>
                    </>
                  ))}
              </div>
            </div>
          ) : null}

          {connectToHubspotNeeded ? (
            <div className={styles.connectToHubspotText}>
              Connect to HubSpot or Salesforce on the extension to proceed
            </div>
          ) : null}

          {step === 1 && !loading ? (
            <div className={styles.bottomSection2}>
              <div className={styles.bottomSection}>
                <LoadingButton
                  onClick={() =>
                    confirmDataClick(
                      selectedCRMTable,
                      selectedIntegration.integration
                    )
                  }
                  loading={loadingConfirmData}
                  disabled={
                    connectToHubspotNeeded || loading || errorGettingData
                  }
                  className={styles.button10}
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  startIcon={
                    !loadingConfirmData ? (
                      <img
                        width="18px"
                        height="18px"
                        src="/check-icon-white.svg"
                      />
                    ) : (
                      ""
                    )
                  }
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "#f7f8fc",
                    fontSize: "16",
                    background:
                      "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                    borderRadius: "8px",
                    "&:hover": {
                      background:
                        "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                    },
                    "&:disabled": {
                      background: "lightgrey",
                      color: "white",
                    },
                    height: 32,
                  }}
                >
                  {!loadingConfirmData ? "Confirm Data" : ""}
                </LoadingButton>
                <LoadingButton
                  onClick={() => writeEmail()}
                  loading={loadingConfirmData}
                  disabled={
                    connectToHubspotNeeded || loading || errorGettingData
                  }
                  className={styles.button10}
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  startIcon={
                    !loadingConfirmData ? (
                      <img
                        width="18px"
                        height="18px"
                        src="/electric_bolt_AI.svg"
                      />
                    ) : (
                      ""
                    )
                  }
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "#ab7ff1",
                    fontSize: "16",
                    background: "white",
                    borderRadius: "8px",
                    "&:hover": {
                      background: "#ede2ff",
                    },
                    "&:disabled": {
                      background: "lightgrey",
                      color: "white",
                    },
                    height: 32,
                  }}
                >
                  {!loadingConfirmData ? "Ask for more info" : ""}
                </LoadingButton>
              </div>
            </div>
          ) : null}
          {step === 2 && !loading ? (
            <div className={styles.bottomSection}>
              <LoadingButton
                onClick={() =>
                  onAddToIntegrationClick(selectedIntegration.integration)
                }
                disabled={
                  selectedIntegration.integration ===
                  GlobalVariables.integrationType.HUBSPOT
                    ? Object.values(selectedCRMColumnHeadersList).some(
                        (item) => item.hubspotValue !== null
                      )
                      ? false
                      : true
                    : Object.values(selectedCRMColumnHeadersList).some(
                        (item) => item.salesforceValue !== null
                      )
                    ? // Check for both closeDate and opportunityName
                      selectedCRMColumnHeadersList["closeDate"]?.salesforceValue
                        ?.label &&
                      selectedCRMColumnHeadersList["opportunityName"]
                        ?.salesforceValue?.label &&
                      selectedCRMColumnHeadersList["dealStage"]?.salesforceValue
                        ?.label &&
                      // New condition: If brokerEmail, brokerFirstName, or brokerLastName exists, ensure brokerLastName exists
                      ((!selectedCRMColumnHeadersList["brokerEmail"]
                        ?.salesforceValue &&
                        !selectedCRMColumnHeadersList["brokerFirstName"]
                          ?.salesforceValue) ||
                        selectedCRMColumnHeadersList["brokerLastName"]
                          ?.salesforceValue?.label)
                      ? false
                      : true
                    : true
                }
                loading={loadingAddToHubspot}
                className={styles.button10}
                style={{
                  maxWidth: "95%",
                  margin: "auto",
                }}
                startIcon={
                  !loadingAddToHubspot ? (
                    <img width="18px" height="18px" src="/plus_white.svg" />
                  ) : (
                    ""
                  )
                }
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#f7f8fc",
                  fontSize: "16",
                  background:
                    "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                  borderRadius: "8px",
                  "&:hover": {
                    background:
                      "linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb)",
                  },
                  "&:disabled": {
                    background: "lightgrey",
                    color: "white",
                  },
                  height: 32,
                }}
              >
                {!loadingAddToHubspot
                  ? `Add to ${selectedIntegration.integration}`
                  : ""}
              </LoadingButton>

              <div className={styles.footerMessage}>
                Add obligatory fields and check contacts
              </div>
            </div>
          ) : null}
          <ToastContainer />
        </main>
      </div>
    );
};

export default Form;
