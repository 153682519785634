import { useState, useEffect, useRef } from "react";
import "./MessageText.css";
// import parse from "html-react-parser";
import { sanitize } from "dompurify";
import { marked } from "marked";
import { v4 as uuidv4 } from "uuid";
import AddyWebSocket from "../models/addyWebSocket";
import { Tooltip } from "@mui/material";

const MessageText = (props) => {
  // const [likeSrc, setLikeSrc] = useState("/thumb.svg");
  const [dislikeSrc, setDislikeSrc] = useState("/thumb-1.svg");
  const copyIconRef = useRef(null);
  const feedbackContainerRef = useRef(null);
  // const [messageText, setMessageText] = useState(props.message.message);

  const message = props.message;
  const documents = props.documents;
  const clientID = props.clientID;
  const isLastMessage = props.isLastMessage;
  const isFirstMessage = props.isFirstMessage;

  // const handleLikeClick = () => {
  //     setLikeSrc("/thumbs-up-filled.png");
  //     setDislikeSrc("/thumb-1.svg");
  // };

  const handleDislikeClick = () => {
    setDislikeSrc("/thumbs-down-filled.png");
    // setLikeSrc("/thumb.svg");
  };

  const getMarkedDownText = (markdownText) => {
    const rawMarkup = marked(markdownText, { sanitize: true });

    // Here marked() may have converted \n into \\n
    // so we replace \\n with <br />
    const lineBreaksAdded = rawMarkup.replace(
      /\\n/g,
      "<br class='addy-line-break' />"
    );

    // Purify the raw markup to prevent XSS attacks
    const purifiedMarkup = sanitize(lineBreaksAdded);
    return { __html: purifiedMarkup };
  };

  const handleCopyClick = () => {
    // eslint-disable-next-line no-undef
    copyIconRef.current.src = "/check_stroke.svg";
    setTimeout(() => {
      copyIconRef.current.src = "/copy_stroke.svg";
    }, 5000);
    // Send copy action to client via WebSocket
    // Define the message as a JSON string because the
    // server expects a string.
    // Use JSON.parse to convert it back to an object on client side.
    // IMPORTANT: Please ensure that the message is a valid JSON string.
    const copiedText = message?.message?.replace(/\\n/g, "\n");
    AddyWebSocket.sendDataToWebsocket(
    copiedText,
    `${clientID}-webpage`,
    "copy",
    3
    );
  };

  const handleInsertClick = () => {
    console.log("Insert clicked");
    // Send insert action to client via WebSocket
    console.log("Insert clicked 2");
    const copiedText = message?.message?.replace(/\\n/g, "\n");
    AddyWebSocket.sendDataToWebsocket(
      copiedText,
      `${clientID}-webpage`,
      "insertTextInReplyEditor"
    );
  };

  useEffect(() => {
    console.log("i am here in feedback message", feedbackContainerRef.current);
    if (feedbackContainerRef.current && !isLastMessage) {
      console.log("i am here in feedback message 222");
      feedbackContainerRef.current.addEventListener("mouseenter", () => {
        console.log("i am here in mouse enter");
        feedbackContainerRef.current.classList.add("show-message-actions");
      });
      feedbackContainerRef.current.addEventListener("mouseleave", () => {
        feedbackContainerRef.current.classList.remove("show-message-actions");
      });
    }
    return () => {
      if (feedbackContainerRef.current) {
        feedbackContainerRef.current.removeEventListener(
          "mouseenter",
          () => {}
        );
        feedbackContainerRef.current.removeEventListener(
          "mouseleave",
          () => {}
        );
      }
    };
  }, [isLastMessage]);

  return (
    <section
      className={`top-bar-new-message5 ${
        message.type == "user"
          ? "user-message"
          : message.type == "assistant"
          ? "assistant-message"
          : ""
      } ${isFirstMessage ? "first-message" : ""} ${
        isLastMessage ? "last-message" : ""
      }`}
      id={message.messageId ? message.messageId : uuidv4()}
    >
      <div className="text-field5">
        <div className="h-heading-group">
          <div className="scroll-bar5">
            <div className="image-placeholder1">
              <div className="wrapper-union">
                <img
                  className="union-icon"
                  loading="lazy"
                  alt={`${message.type}`}
                  src={`${message.imageURL}`}
                />
              </div>
            </div>

            <div className="frame-parent1">
              <div className="h2-heading-parent24">
                <b className="h2-heading49">
                  {message.name && message.name.length > 40
                    ? `${message.name.substring(0, 39)}...`
                    : message.name}
                </b>
                {message.type === "user" && message.selectedText && (
                  <div className="selected-text-parent">
                    <div className="selected-text-header-parent">
                      <div className="selected-text-header">
                        {message.selectedTextTitle || "Selected text"}
                      </div>
                    </div>
                    <div className="selected-text">
                      {message.selectedText.length > 250
                        ? message.selectedText.substring(0, 250) + "..."
                        : message.selectedText}
                    </div>
                  </div>
                )}

                {documents && documents.length > 0 && (
                  <div>
                    {documents.map((context, index) => (
                      <Tooltip title={context.text ? `Document Title: ${context.title}\n Document Content: ${context.text}` : `Document Content: ${context}`} arrow>
                        <div key={index} style={{ display: "inline-block" }}>
                          <span
                            style={{
                              padding: "5px",
                              color: "blue", // Make the text blue like a hyperlink
                              textDecoration: "underline", // Add underline like a link
                              cursor: "pointer",
                              fontSize: "0.8em", // Make the numbers smaller
                            }}
                          >
                            {index + 1}
                          </span>
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                )}
                <div className="h2-heading50">
                  <span>
                    {message.action == "thinking" && (
                      <div className="dot"></div>
                    )}

                    {/* eslint-disable-next-line max-len */}
                    {/* <div className={`model-response-text ${message.action == "thinking" ? "hide" : ""}`}>
                                            {parse(message.message)}
                                        </div> */}
                    <div
                      className={`model-response-text ${
                        message.action == "thinking" ? "hide" : ""
                      }`}
                      dangerouslySetInnerHTML={getMarkedDownText(
                        message.message
                      )}
                    />
                  </span>
                </div>
              </div>

              {message.type === "assistant" && (
                <div
                  ref={feedbackContainerRef}
                  className={`feedback-container frame-parent2 ${
                    message.action == "thinking" && !message?.message?.length
                      ? "hide"
                      : ""
                  } ${isLastMessage ? "show-message-actions" : ""}`}
                >
                  <div className="addy-copy-response-div">
                    <div
                      className="copy-response thumb-group"
                      onClick={handleInsertClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="insert"
                        src="/insert_stroke.svg"
                        data-toggle="tooltip"
                        title="Insert in email editor"
                      />
                      <div className="h2-heading52">Insert</div>
                    </div>

                    <div
                      className="copy-response thumb-group"
                      onClick={handleCopyClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="copy"
                        src="/copy_stroke.svg"
                        data-toggle="tooltip"
                        title="Copy to clipboard"
                        ref={copyIconRef}
                      />
                      <div className="h2-heading52">Copy</div>
                    </div>
                    <div
                      className="copy-response thumb-group"
                      onClick={props.onRephraseButtonClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="rephrase"
                        src="/rephrase.svg"
                        data-toggle="tooltip"
                        title="Rephrase the provided email"
                      />
                      <div className="h2-heading52">Rephrase</div>
                    </div>
                  </div>

                  <div className="addy-rate-response-div">
                    {/* <div className="thumb-parent">
                                            <img
                                                data-toggle="tooltip"
                                                title="Good response"
                                                className="like-thumb thumb-icon"
                                                loading="lazy"
                                                alt=""
                                                src={likeSrc}
                                                onClick={handleLikeClick}
                                            />
                                            <div className="h2-heading51"></div>
                                        </div> */}
                    {/* <div className="thumb-parent">
                      <img
                        data-toggle="tooltip"
                        title="Bad response"
                        className="dislike-thumb thumb-icon"
                        alt=""
                        src={dislikeSrc}
                        onClick={handleDislikeClick}
                      />
                      <div className="h2-heading52"></div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MessageText;
