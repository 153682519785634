// import ButtonInstance from "./ButtonInstance";
import styles from "./SuggestedReplyApproaches.module.css";
import {useEffect, useState} from "react";

const EmailSummary = (props) => {
    const [suggestedReplyWays, setSuggestedReplyWays] = useState([]);
    const [sendersIntent, setSendersIntent] = useState("");
    const [emailSummary, setEmailSummary] = useState("");
    const [currentEmailAction, setCurrentEmailAction] = useState("");

    useEffect(() => {
        setSuggestedReplyWays(props?.suggestedReplyWays || []);
        setSendersIntent(props?.sendersIntent || "");
        setEmailSummary(props?.emailSummary || "");
        setCurrentEmailAction(props?.currentEmailAction || "");
        // console.log("Props:", props);
    }, [props]);

    return (

        <div className={styles.messageContent}>
            <img src="/close.svg" width="12" height="12" className={styles.closeIcon} alt="Close"
                onClick={props.onCloseClick}/>

            {emailSummary && emailSummary.length &&
                <div className={styles.userMessage}>
                    <div className={styles.waysYouCan}>Conversation Summary</div>
                    <p className={styles.johnWantsTo}>
                        {emailSummary}
                    </p>
                </div>
            }


            {suggestedReplyWays && suggestedReplyWays.length &&
                <div>
                    {currentEmailAction === "Reply quickly" && <div className={styles.waysYouCan}>⚡ Ways You Can Reply</div>}
                    {currentEmailAction === "Follow up" && <div className={styles.waysYouCan}>⚡ Suggestions for you</div>}


                    <div className={styles.replyButtons}>
                        {suggestedReplyWays.map((way, index) => {
                            const firstSpaceIndex = way.indexOf(" ");
                            let firstWord = way;
                            let restOfWords = "";

                            if (firstSpaceIndex !== -1) {
                                firstWord = way.substring(0, firstSpaceIndex);
                                restOfWords = way.substring(firstSpaceIndex + 1);
                            }
                            return (<div className={styles.button} onClick={
                                props.onSuggestedReplyApproachClick(way,
                                    index, sendersIntent)}>
                                {/* <div className={styles.div}>⚡</div> */}
                                <div className={styles.politelyAgreeContainer}>
                                    {/* <span className={styles.politelyAgree}>
                                    {"Politely Agree "}
                                </span> */}
                                    {/* <span className={styles.heyMikeThanks}>
                                        {way}
                                    </span> */}
                                    <span className={styles.heyMikeThanks} style={{
                                        fontWeight: "bold",
                                    }}>{firstWord + " "}</span>
                                    <span className={styles.heyMikeThanks}>
                                        {restOfWords}</span>

                                </div>
                            </div>);
                        })}

                        {/* <ButtonInstance hideSuggestedPrompts={true}
                            placeholder="Custom prompt"
                            maxCharsPerLine={17}
                            hideFeedbackRequestText={true}
                            showMoreSuggestionsForYouTitle={false} /> */}

                    </div>

                </div>
            }

        </div>
    );
};

export default EmailSummary;
