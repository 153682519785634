import ActionButton from "./ActionButton";
import styles from "./EmailActionSuggestions.module.css";
import {useState, useEffect} from "react";
import SuggestedReplyApproaches from "./SuggestedReplyApproaches";
import EmailSummary from "./EmailSummary";


const EmailActionSuggestions = (props) => {
    // Current view can be "suggestions", "staging"
    const [currentView, setCurrentView] = useState("suggestions");
    const [currentEmailAction, setCurrentEmailAction] = useState("");
    const [emailSummary, setEmailSummary] = useState("");
    const [emailThread, setEmailThread] = useState("");
    const [documents, setDocuments] = useState([]);
    const [userSubscriptionInfo, setUserSubscriptionInfo] = useState(null);
    const [contactsInCurrentThread, setContactsInCurrentThread] = useState(null);
    const [loading, setLoading] = useState(true);

    const containsAttachments = props.attachmentListBase64?.length > 0;
    const uid = props.uid;
    const name = props.name;
    const email = props.email;
    const language = props.language;

    const [suggestedReplyApproaches, setSuggestedReplyApproaches] = useState([]);
    const [sendersIntent, setSendersIntent] = useState("");
    const [firstName, setFirstName] = useState("");

    const onFollowUpEmailClick = () => {
        // Use react router to go to the /form route
        props.onFollowUpEmailClick();
    };

    useEffect(() => {
        // console.log("Initial Email Action Suggestions View", props.initialEmailActionSuggestionsView);
        // console.log("Current Email Action", props.currentEmailAction);
    
        // if (props.initialEmailActionSuggestionsView === "staging" &&
        //     props.currentEmailAction === "Follow up" && emailThread) {
        //     onFollowUpEmailClick();
        //     setCurrentView("suggestions");
        // }
    }, [props.initialEmailActionSuggestionsView, props.currentEmailAction,
        emailThread]);


    // eslint-disable-next-line no-unused-vars
    const handleSuggestReply = () => {
        setCurrentView("staging");
    };

    const handleSummarizeDetails = () => {
        setCurrentView("summary");
    };

    async function fetchResponseData(requestData, endpoint, attributeToget) {
        const data = requestData;
        let returnVal;
        await fetch(endpoint, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    returnVal = data[attributeToget];
                } else {
                    returnVal = undefined;
                }
            }).catch((error) => {
                returnVal = undefined;
            });
        return returnVal;
    }

    const getSendersIntentAndPossibleWaysToReply = () => {
        const API_URL_DIRECT_INTENT = "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/api";
        const data = {
            "uid": uid,
            "authEmail": email,
            "subscription": "unlimited",
            "email": emailThread,
            "sender": name,
            "language": language,
        };
        const intent = fetchResponseData(data, `${API_URL_DIRECT_INTENT}/thread/intent`, "response");
        const approaches = fetchResponseData(data, `${API_URL_DIRECT_INTENT}/thread/suggested-ways`, "response");

        Promise.all([intent, approaches]).then(([intent, approaches]) => {
            // console.log("Successfully generated intents");
            if (intent && intent.length &&
                approaches && approaches.length) {
                // update state
                setSendersIntent(intent);
                setSuggestedReplyApproaches(approaches);
            }
        });
    };

    // eslint-disable-next-line no-unused-vars
    const getEmailSummaryFromAI = (emailThread) => {
        const data = {
            "uid": uid,
            "authEmail": email,
            "subscription": "unlimited",
            "formattedThread": emailThread,
            "summaryLengthWords": 30,
        };
        const endpoint = "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/emailSummary/summary/summary";
        // console.log("Getting email summary");
        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const summary = data["response"];
                    setEmailSummary(summary);
                } else {
                    throw new Error("SummaryResponseNotOK");
                }
            }).catch((error) => {
                console.error(error);
            });
    };


    const getFirstNameFromName = (name) => {
        if (name === "You") return "there";
        const firstSpaceIndex = name.indexOf(" ");
        let firstWord = name;
        if (firstSpaceIndex !== -1) {
            firstWord = name.substring(0, firstSpaceIndex);
        }
        if (firstWord == "undefined" || firstWord == "null" || firstWord == "") {
            firstWord = "there";
        }
        return firstWord;
    };

    useEffect(() => {
        setSuggestedReplyApproaches(props?.suggestedReplyApproaches || []);
        setSendersIntent(props?.sendersIntent || "");
        setCurrentEmailAction(props?.currentEmailAction);
        setEmailSummary(props?.emailSummary || "");
        setEmailThread(props?.emailThread || "");
        setDocuments(props?.documents || []);
        setUserSubscriptionInfo(props?.userSubscriptionInfo || null);
        setCurrentView(props?.initialEmailActionSuggestionsView || "suggestions");
        setContactsInCurrentThread(props?.contactsInCurrentThread || null);
        // console.log("Contacts in current thread", props?.contactsInCurrentThread);
        // Update the first name
        const firstName = getFirstNameFromName(name);
        setFirstName(firstName ? firstName : name);
        // console.log("Props:", props);
        // If there are no suggested reply approaches and senders intent
        // Get it from the server
        if (!(props?.suggestedReplyApproaches && props?.sendersIntent)) {
            // TODO: Get the suggested reply approaches and senders intent
            if (emailThread) {
                setTimeout(() => {
                    getSendersIntentAndPossibleWaysToReply();
                }, 2000);
            }
        }
        // console.log("props in email action suggestions", props);
    }, [props, props?.initialEmailActionSuggestionsView]);

    // Reload when email summary changes
    useEffect(() => {}, [emailSummary]);

    // Run effect on component mount
    useEffect(() => {
        const timerId = setTimeout(() => {
            // After 5 seconds (5000 ms), set loading state to false
            setLoading(false);
        }, 5000);

        // Return cleanup function
        return () => {
            // If unmounting before 5 seconds have passed, clear the timeout
            clearTimeout(timerId);
        };
    }, []);

    const onSuggestedReplyApproachClick = (way, index, intent) => {
        props.onSuggestedReplyApproachClick(way, index, intent);
    };

    const onExtractLoanOpportunityClick = () => {
        // Use react router to go to the /form route
        props.onExtractLoanOpportunityClick();
    };

    const onRunBackgroundSearchClick = () => {
        props.onRunBackgroundSearchClick();
    }

    const onComposeEmailInMyVoiceClick = () => {
        // Use react router to go to the /form route
        props.onComposeEmailInMyVoiceClick();
    };

    const onDocumentAnalyzeClick = () => {
      // Use react router to go to the /form route
    //   props.onDocumentAnalyzeClick();
    };
    
    const onDropDownChange = (selectedOption, dropDownId) => {
        if (dropDownId === "contactsInCurrentThread") {
            props.onContactsDropdownChange(selectedOption);
        }
    }


    return (
        <div className={styles.suggestionButtons}>
            <div>
                <b className={styles.h2Heading}>Hello, {firstName}</b>
                <h2 className={styles.h2Heading1}>{"How can I help you today? "}</h2>
            </div>

            {!emailThread && currentEmailAction !== "Compose" && currentView === "suggestions" &&
                loading && (
                <div className="staging-container">
                    <div className="staging-content">
                        <div className="skeleton-line"><div className="skeleton-animation"></div></div>
                        <div className="short-skeleton-line skeleton-line"><div className="skeleton-animation"></div></div>
                    </div>

                    <div className="staging-content">
                        <div className="skeleton-line"><div className="skeleton-animation"></div></div>
                        <div className="short-skeleton-line skeleton-line"><div className="skeleton-animation"></div></div>
                    </div>
                </div>
            )}

            {/* Suggestions for the user to take action on this email */}
            {(emailThread || currentEmailAction === "Compose") && currentView === "suggestions" && (<div className={styles.suggestionButtonsContainer}>
                
                {(currentEmailAction === "Reply quickly" || currentEmailAction === "Follow up" ||
                        currentEmailAction === "Analyze attachment") && (
                    <ActionButton suggestAReply="Extract Loan Opportunity" toThisEmail={`from this email ${containsAttachments ? "and attachments" : ""}`}
                        onClick={() => onExtractLoanOpportunityClick()}/>
                )}

                {(currentEmailAction === "Reply quickly" || currentEmailAction === "Follow up" ||
                        currentEmailAction === "Analyze attachment") && (
                    <ActionButton suggestAReply="Run a background search" toThisEmail={`${contactsInCurrentThread?.length ? "on" : ""}`}
                        dropDownOptions={contactsInCurrentThread}
                        dropDownOptionsDisplayAttribute="name"
                        dropDownId="contactsInCurrentThread"
                        onDropDownChange={onDropDownChange}
                        onClick={() => onRunBackgroundSearchClick()}/>
                )}

                {currentEmailAction === "Reply quickly" && suggestedReplyApproaches && suggestedReplyApproaches.length && (
                    <ActionButton suggestAReply="Suggest a reply" toThisEmail="to this email"
                        onClick={() => handleSuggestReply()} />
                )}
                {currentEmailAction === "Analyze attachment" && (
                    <ActionButton suggestAReply="Summarize the main points" toThisEmail="in this document" />
                )}
                {currentEmailAction === "Follow up" && (
                    <ActionButton suggestAReply="Suggest a Follow up" toThisEmail="to this email"
                        onClick={() => onFollowUpEmailClick()} />
                )}
                {currentEmailAction === "Compose" && (
                    <ActionButton suggestAReply="Compose an email" toThisEmail="in my voice"
                        onClick={() => onComposeEmailInMyVoiceClick()}/>
                )}
                {(documents && documents.length > 0) && (
                    <ActionButton suggestAReply="Analyze" toThisEmail="the selected document"
                        onClick={() => onDocumentAnalyzeClick()} />
                )}
                {(currentEmailAction === "Reply quickly" || currentEmailAction === "Follow up") && (
                    <ActionButton suggestAReply="Summarize details" toThisEmail={`in this email ${containsAttachments ? "and attachments" : ""}`}
                        onClick={() => handleSummarizeDetails()}/>
                )}
            </div>)}

            {/* Staging area for the user to take action on this email */}
            {(emailThread || props.initialEmailActionSuggestionsView === "staging") && currentView === "staging" && (<div>
                {currentEmailAction === "Reply quickly" && (
                    <SuggestedReplyApproaches
                        onCloseClick={() => setCurrentView("suggestions")}
                        suggestedReplyWays={suggestedReplyApproaches}
                        sendersIntent={sendersIntent}
                        onSuggestedReplyApproachClick={(way, index, intent) =>
                            () => onSuggestedReplyApproachClick(way, index, intent)}
                    />
                )}
            </div>)}

            {/* Summary of this email */}
            {emailThread && currentView === "summary" && (
                <div>
                    <EmailSummary
                        onCloseClick={() => setCurrentView("suggestions")}
                        suggestedReplyWays={currentEmailAction === "Reply quickly" ? suggestedReplyApproaches :
                            currentEmailAction === "Follow up" ? ["Generate a follow up email"] : []}
                        sendersIntent={sendersIntent}
                        emailSummary={emailSummary}
                        currentEmailAction={currentEmailAction}
                        onSuggestedReplyApproachClick={(way, index, intent) =>
                            () => onSuggestedReplyApproachClick(way, index, intent)}
                    />
                </div>
            )}
        </div>
    );
};

export default EmailActionSuggestions;
