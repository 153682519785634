import styles from "./ConnectSalesforce.module.css";
import { useEffect, useState } from "react";
import Salesforce from "../models/salesforce";

const ConnectSalesforce = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const code = urlParams.get("code");
  const email = urlParams.get("state");

  async function connectToSalesforce() {
    const response = await Salesforce.connectToSalesforce(code, email);
    if (!response.success) {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    connectToSalesforce();
  }, []);

  return (
    <div
      className={styles.commercial}
      style={{ width: "100vw", height: "100vh" }}
    >
      <main className={styles.topBarNewMessageParent}>
        <header className={styles.topBarNewMessage}>
          <div className={styles.commercial1}>
            <div className={styles.commercialDesign}>
              <div className={styles.wrapperUnion}>
                <img
                  className={styles.unionIcon}
                  loading="lazy"
                  alt=""
                  src="/union.svg"
                />
              </div>
              <div className={styles.topBarNewMessage1}>
                <b className={styles.h2Heading}>Addy</b>
              </div>
            </div>
          </div>
        </header>
        <section style={{ width: "100vw", height: "100vh" }}>
          {loading ? (
            <div
              className={styles.loadingTexCenter}
              style={{ textAlign: "center" }}
            >
              <div className="dot"></div>Connecting to Salesforce...
            </div>
          ) : error ? (
            <div className={styles.loadingText} style={{ textAlign: "center" }}>
              <mark className={styles.checkMark}>✖</mark>
              There was an error connecting to Salesforce. Please try again. If
              the problem persists, please contact support.
            </div>
          ) : (
            <div className={styles.loadingText} style={{ textAlign: "center" }}>
              <mark className={styles.checkMark}>✔</mark>
              Successfully connected to Salesforce. You can now close this
              window.
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default ConnectSalesforce;
