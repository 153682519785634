/* eslint-disable indent */
/* eslint-disable max-len */
class GlobalVariables {
    static isProd() {
        return process.env.REACT_APP_NODE_ENV === "prod";
    }
    static isDev() {
        return process.env.REACT_APP_NODE_ENV === "development";
    }
    static isBatman() {
        return process.env.REACT_APP_IAMBATMAN === "YES";
    }
    static getAPIURL() {
        return GlobalVariables.isProd()
          ? "https://us-central1-hey-addy-chatgpt.cloudfunctions.net"
          : GlobalVariables.isDev()
          ? "https://us-central1-addy-ai-dev.cloudfunctions.net"
          : "http://127.0.0.1:5003/addy-ai-dev/us-central1";
    }

    static getCloudRunAPIURL() {
      return GlobalVariables.isProd()
        ? "https://backend-prod-zquodzeuva-uc.a.run.app"
        : GlobalVariables.isDev()
        ? "https://backend-dev-111911035666.us-central1.run.app"
        : "http://127.0.0.1:5003/addy-ai-dev/us-central1";
    }

    static get brokerTypeList() {
        return ["Commercial Real Estate", "Residential Real Estate", "N/A"];
    }

    static get yesNoList() {
        return ["Yes", "No", "N/A"];
    }

    static get newOrExisting() {
        return ["New Business", "Existing Business", "N/A"];
    }

    static get opportunityType() {
        return ["Construction", "Suboriginator", "Sure", "N/A"];
    }

    static get opportunityDealStage() {
        return ["Qualify", "Meet & Present", "Propose", "Negotiate", "Closed Won", "Closed Lost", "Prospecting"]
    }

    static get integrationType(){
        return {
            SALESFORCE: "Salesforce",
            HUBSPOT: "HubSpot",
        }
    }

    static getCorrectRenderData(selectedTable) {
        switch (selectedTable.toLowerCase()) {
            case "contacts":
                return GlobalVariables.getContactSampleRenderData;
            case "contacts-salesforce":
                return GlobalVariables.getContactSalesforceSampleRenderData;
            case "companies":
                return GlobalVariables.getCompaniesSampleRenderData;
            case "deals":
                return GlobalVariables.getDealersSampleRenderData;
            case "opportunities":
                return GlobalVariables.getOpportunitiesSampleRenderData;
            default:
                return GlobalVariables.getContactSampleRenderData;
        }
    }

    static get getContactSalesforceSampleRenderData() {
        return [
            {
                "attribute": "brokerFirstName", // Required. The key to identify this value and store the value in the form data
                "type": "singleLineText", // Options: singleLineText, dropdown
                "value": "John", // Required. The value to be displayed in the input field
                "placeholder": "John", // Optional, default is empty string
                "display": "First Name", // Required. Display text title for the field
                "confidence": 10, // Required. Confidence level score, If not available, pass -1
                "CRMColumnHeader": "First Name", // Required when adding to CRM. CRM column header name
            },
            {
                "attribute": "brokerLastName", // Required. The key to identify this value and store the value in the form data
                "type": "singleLineText", // Options: singleLineText, dropdown
                "value": "Logan", // Required. The value to be displayed in the input field
                "placeholder": "Logan", // Optional, default is empty string
                "display": "Last Name *", // Required. Display text title for the field
                "confidence": 10, // Required. Confidence level score, If not available, pass -1
                "CRMColumnHeader": "Last Name", // Required when adding to CRM. CRM column header name
            },
            {
                "attribute": "brokerEmail",
                "type": "singleLineText",
                "value": "logan.mentz@cbre.com",
                "display": "Email",
                "confidence": 10,
                "CRMColumnHeader": "Email",
            },
        ];
    }

    static get getContactSampleRenderData() {
        return [
            {
                "attribute": "firstName", // Required. The key to identify this value and store the value in the form data
                "type": "singleLineText", // Options: singleLineText, dropdown
                "value": "Logan", // Required. The value to be displayed in the input field
                "placeholder": "John", // Optional, default is empty string
                "display": "First Name", // Required. Display text title for the field
                "confidence": 8, // Required. Confidence level score, If not available, pass -1
                "CRMColumnHeader": "First Name", // Required when adding to CRM. CRM column header name
            },
            {
                "attribute": "lastName",
                "type": "singleLineText",
                "value": "Mentz",
                "display": "Last Name",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "email",
                "type": "singleLineText",
                "value": "logan.mentz@cbre.com",
                "display": "Email",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "phone",
                "type": "singleLineText",
                "value": "+1 586 531-1691",
                "display": "Phone",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "brokerType",
                // "type": "singleLineText",
                "type": "dropdown",
                "value": GlobalVariables.yesNoList, // For dropdown, pass an array of strings
                "selectedIndex": 0, // Required for dropdown, index of the selected value
                "display": "Broker",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "companyName",
                "type": "singleLineText",
                "value": "CBRE | Global Workplace Solutions",
                "display": "Company's Name",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "requestedLoanAmount",
                "type": "singleLineText",
                "value": "$1M-$1.5M",
                "display": "Requested Loan Amount",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "propertyLocation",
                "type": "singleLineText",
                "value": "Romeo, Michigan, United States",
                "display": "Property Location",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "loanUrgency",
                "type": "dropdown",
                "value": GlobalVariables.yesNoList, // For dropdown, pass an array of strings
                "selectedIndex": 0, // Required for dropdown, index of the selected value
                "display": "Loan Urgency",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "loanDuration",
                "type": "singleLineText",
                "value": "Urgent",
                "display": "Loan Duration",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "assignedAgent", // Required. The key to identify this value and store the value in the form data
                "type": "dropdown", // Options: singleLineText, dropdown
                "value": [], // For dropdown, pass an array of strings
                "selectedIndex": 0, // Required for dropdown, index of the selected value
                "display": "Assigned Agent",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
        ];
    }

    static get getCompaniesSampleRenderData() {
        return [
            {
                "attribute": "firstName", // Required. The key to identify this value and store the value in the form data
                "type": "singleLineText", // Options: singleLineText, dropdown
                "value": "Logan", // Required. The value to be displayed in the input field
                "placeholder": "John", // Optional, default is empty string
                "display": "First Name", // Required. Display text title for the field
                "confidence": 8, // Required. Confidence level score, If not available, pass -1
                "CRMColumnHeader": "First Name", // Required when adding to CRM. CRM column header name
            },
            {
                "attribute": "lastName",
                "type": "singleLineText",
                "value": "Mentz",
                "display": "Last Name",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "assignedAgent", // Required. The key to identify this value and store the value in the form data
                "type": "dropdown", // Options: singleLineText, dropdown
                "value": [], // For dropdown, pass an array of strings
                "selectedIndex": 0, // Required for dropdown, index of the selected value
                "display": "Assigned Agent",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
        ];
    }

    static get getDealersSampleRenderData() {
        return [
            {
                "attribute": "dealName",
                "type": "singleLineText",
                "value": "Logan",
                "display": "Deal Name",
                "confidence": 8,
                "CRMColumnHeader": "Deal Name",
            },
            {
                "attribute": "dealPipeline",
                "type": "dropdown",
                "value": [],
                "selectedIndex": 0,
                "display": "Deal Pipeline",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "dealAmount",
                "type": "singleLineText",
                "value": "10000",
                "display": "Deal Amount",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "dealStage",
                "type": "dropdown",
                "value": [],
                "selectedIndex": 0,
                "display": "Deal Stage",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "dealType",
                "type": "dropdown",
                "value": GlobalVariables.newOrExisting,
                "selectedIndex": 0,
                "display": "Deal Type",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "assignedAgent",
                "type": "dropdown",
                "value": [],
                "selectedIndex": 0,
                "display": "Assigned Agent",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
        ];
    }

    static get getOpportunitiesSampleRenderData() {
        return [
            {
                "attribute": "opportunityName",
                "type": "singleLineText",
                "value": "Logan",
                "display": "Opportunity Name *",
                "confidence": 8,
                "CRMColumnHeader": "Opportunity Name",
            },
            {
                "attribute": "closeDate",
                "type": "singleLineText",
                "value": "20/20/2020",
                "display": "Close Date *",
                "confidence": 8,
                "CRMColumnHeader": "Close Date",
            },
            {
                "attribute": "dealType",
                "type": "dropdown",
                "value": GlobalVariables.opportunityType,
                "selectedIndex": 0,
                "display": "Type",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "dealStage",
                "type": "dropdown",
                "value": GlobalVariables.opportunityDealStage,
                "selectedIndex": 0,
                "display": "Stage *",
                "confidence": 8,
                "CRMColumnHeader": "",
            },
            {
                "attribute": "brokerageIncludingGST",
                "type": "singleLineText",
                "value": "",
                "display": "Brokerage Including GST",
                "confidence": 8,
                "CRMColumnHeader": "Brokerage Including GST",
            },
            {
                "attribute": "establishmentFee",
                "type": "singleLineText",
                "value": "",
                "display": "Establishment Fee",
                "confidence": 8,
                "CRMColumnHeader": "Establishment Fee",
            },
            {
                "attribute": "interestRateHigherRate",
                "type": "singleLineText",
                "value": "",
                "display": "Interest Rate Higher Rate",
                "confidence": 8,
                "CRMColumnHeader": "Interest Rate Higher Rate",
            },
            {
                "attribute": "interestRateLowerRate",
                "type": "singleLineText",
                "value": "",
                "display": "Interest Rate Lower Rate",
                "confidence": 8,
                "CRMColumnHeader": "Interest Rate Lower Rate",
            }
        ];
    }

    static getClientDomain() {
        return ["https://app.addy-ai.com/", "http://localhost:3000/", "https://devmail.addy.so/"]
    }

    static peopleDataLabsEndpoint() {
        return "https://api.peopledatalabs.com/v5";
    }

    static getPeopleDataLabsAPIKey() {
        return process.env.PEOPLE_DATA_LABS_API_KEY;
    }
}

module.exports = GlobalVariables;
