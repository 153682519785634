import styles from "./ConfidenceLevel.module.css";

const ConfidenceLevel = (props) => {
    const score = props.score;
    // let confidenceLevel;
    let color;
    if (score > 7) {
        // confidenceLevel = "Spot on";
        color = "#32d788";
    } else if (score > 4) {
        // confidenceLevel = "Acceptable";
        color = "#EDBC0E";
    } else {
        // confidenceLevel = "Inaccurate";
        color = "#ED360E";
    }

    return (
        <div className={styles.frameParent}>

            <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                    <div className={styles.ellipseWrapper}>
                        <div className={styles.frameChild} style={{
                            backgroundColor: color,
                        }}/>
                    </div>
                    {/* <div className={styles.h2Heading1}>
                        {confidenceLevel}
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ConfidenceLevel;
