// import ButtonInstance from "./ButtonInstance";
import styles from "./SuggestedReplyApproaches.module.css";
import {useEffect, useState} from "react";

const SuggestedReplyApproaches = (props) => {
    const [suggestedReplyWays, setSuggestedReplyWays] = useState([]);
    const [sendersIntent, setSendersIntent] = useState("");

    useEffect(() => {
        setSuggestedReplyWays(props?.suggestedReplyWays || []);
        setSendersIntent(props?.sendersIntent || "");
        // console.log("Props:", props);
    }, [props]);

    // eslint-disable-next-line no-unused-vars
    const onCustomPromptClick = (prompt, selectedText) => {
        props.onSuggestedReplyApproachClick(prompt, -1, sendersIntent);
    };

    return (

        <div className={styles.messageContent}>
            <img src="/close.svg" width="12" height="12" className={styles.closeIcon} alt="Close"
                onClick={props.onCloseClick}/>

            {sendersIntent && <div className={styles.userMessage}>
                <p className={styles.johnWantsTo}>
                    {sendersIntent}
                </p>
            </div>}


            {suggestedReplyWays && suggestedReplyWays.length > 0 &&
                <div>
                    <div className={styles.waysYouCan}>⚡ Ways You Can Reply</div>

                    <div className={styles.replyButtons}>
                        {suggestedReplyWays.map((way, index) => {
                            const firstSpaceIndex = way.indexOf(" ");
                            let firstWord = way;
                            let restOfWords = "";

                            if (firstSpaceIndex !== -1) {
                                firstWord = way.substring(0, firstSpaceIndex);
                                restOfWords = way.substring(firstSpaceIndex + 1);
                            }
                            return (<div className={styles.button} key={index}
                                onClick={
                                    props.onSuggestedReplyApproachClick(way,
                                        index, sendersIntent)}>
                                {/* <div className={styles.div}>⚡</div> */}
                                <div className={styles.politelyAgreeContainer}>
                                    {/* <span className={styles.politelyAgree}>
                                    {"Politely Agree "}
                                </span> */}
                                    {/* <span className={styles.heyMikeThanks}>
                                        {way}
                                    </span> */}
                                    <span className={styles.heyMikeThanks} style={{
                                        fontWeight: "bold",
                                    }}>{firstWord + " "}</span>
                                    <span className={styles.heyMikeThanks}>
                                        {restOfWords}</span>

                                </div>
                            </div>);
                        })}

                        {/* <ButtonInstance hideSuggestedPrompts={true}
                            placeholder="Custom prompt"
                            maxCharsPerLine={17}
                            hideFeedbackRequestText={true}
                            onClick={(prompt, selectedText) =>
                                onCustomPromptClick(prompt, selectedText)}
                            showMoreSuggestionsForYouTitle={false} /> */}

                    </div>

                </div>}
        </div>
    );
};

export default SuggestedReplyApproaches;
