const GlobalVariables = require("./globalVariables"); // Import the necessary module

class AddyWebSocket {
    /**
     * Sends a structured message to the websocket endpoint
     * @static
     * @async
     * @param {string} text - The actual text message you want to send.
     * @param {string} clientID - The ID of the client who will receive the message.
     * @param {string} action - The action that this message performs on
     * the websocket.
     * @return {Promise<boolean>} A promise that resolves to `true` if
     * the message was sent successfully,
     * and `false` otherwise.
     */
    static async sendDataToWebsocket(text, clientID, action) {
        // Using window post message api instead
        if (window?.parent?.postMessage) {
            this.sendMessageToWebpageViaPostMessageAPI(action, text);
            return true;
        } else {
            console.log("Post message API not available");
            return false;
        }

        // eslint-disable-next-line no-unreachable
        const messageObject = {
            "action": action,
            "text": text,
        };
        const messageString = JSON.stringify(messageObject);
        const fetchURL = GlobalVariables.getAPIURL() +
            `/webSocket/webSocket/send/${clientID}`;

        // Define the post request
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({"message": messageString}),
        };

        // Make the request
        return fetch(fetchURL, requestOptions).then((response) => {
            if (response.status != 200) {
                // console.log("Status:", response.status, "Action:", action);
                console.log("Error: ", response.status, action);
                return false;
            }
            return true;
        }).catch((error) => {
            console.error("Error:", error, action);
            return false;
        });
    }

    static async sendMessageToWebpageViaPostMessageAPI(key, value) {
        window?.parent?.postMessage({key: key, value: value}, "*");
    }


    /**
     * Tries multiple times to send a message to the websocket endpoint until
     * it's successful or the max attempts limit is reached.
     * @static
     * @async
     * @param {string} text - The actual text message you want to resend.
     * @param {string} clientID - The ID of the client who will receive the message.
     * @param {string} action - The action that this message performs
     * @param {number} [maxAttempts=10] - Max number of attempts to resend message.
     */
    static async retrySendDataToWebsocket(text, clientID, action, maxAttempts = 10){
        let attemptCount = 0;

        const intervalId = setInterval(async () => {
            const result = await this.sendDataToWebsocket(text, clientID, action);

            if (result) {
                // console.log("result", result);
                console.log(`Success! ${action} sent.`);
                clearInterval(intervalId); // clear interval on success
            } else {
                attemptCount += 1;
                // eslint-disable-next-line max-len
                console.log(`Attempt ${attemptCount} failed for ${action} clientId ${clientID}.`);

                if (attemptCount >= maxAttempts) {
                    console.log("Max attempts reached. Stopping...");
                    clearInterval(intervalId); // clear interval after max attempts
                }
            }
        }, 500);
    }
}

module.exports = AddyWebSocket;
