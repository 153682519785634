/* eslint-disable max-len */
const GlobalVariables = require("./globalVariables"); // Import the necessary module

class Hubspot {
    static getEndpoints() {
        return {
            connectToHubspot: "/hubspot/hubspot/connect",
            getHubspotObjects: "/hubspot/hubspot/getHubspotObjects",
            getObjectProperties: "/hubspot/hubspot/getObjectProperties",
            addToHubspot: "/hubspot/hubspot/addObject",
            getAllDealsPipelines: "/hubspot/hubspot/getAllDealsPipelines",
        };
    }

    static async connectToHubspot(code, email) {
        const ENDPOINT = GlobalVariables.getAPIURL() + Hubspot.getEndpoints().connectToHubspot;
        // eslint-disable-next-line max-len
        return await fetch(`${ENDPOINT}?code=${code}&email=${email}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    return {success: true, response: data};
                } else {
                    return {success: false, reason: "internal server error"};
                }
            }).catch((error) => {
                console.error(error);
                return {success: false, reason: "internal server error"};
            });
    }

    static async getObjects(email) {
        const ENDPOINT = GlobalVariables.getAPIURL() + Hubspot.getEndpoints().getHubspotObjects;
        // eslint-disable-next-line max-len
        return await fetch(`${ENDPOINT}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    return {success: true, objects: data.hubspotObjects};
                } else {
                    return {success: false, reason: "internal server error"};
                }
            }).catch((error) => {
                console.error(error);
                return {success: false, reason: "internal server error"};
            });
    }

    static async getObjectProperties(email, objectType) {
        const ENDPOINT = GlobalVariables.getAPIURL() + Hubspot.getEndpoints().getObjectProperties;
        // eslint-disable-next-line max-len
        return await fetch(`${ENDPOINT}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                crmField: objectType,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    return {success: true, properties: data.hubspotObjects};
                } else {
                    return {success: false, reason: "internal server error"};
                }
            }).catch((error) => {
                console.error(error);
                return {success: false, reason: "internal server error"};
            });
    }

    static async addToHubspot(email, properties) {
        const ENDPOINT = GlobalVariables.getAPIURL() + Hubspot.getEndpoints().addToHubspot;
        // eslint-disable-next-line max-len
        return await fetch(`${ENDPOINT}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                properties: JSON.stringify(properties),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    return {success: true, properties: data.hubspotObjects};
                } else {
                    return {success: false, reason: "internal server error"};
                }
            }).catch((error) => {
                console.error(error);
                return {success: false, reason: "internal server error"};
            });
    }

    static async getAllDealsPipelines(email) {
        const ENDPOINT = GlobalVariables.getAPIURL() + Hubspot.getEndpoints().getAllDealsPipelines;
        // eslint-disable-next-line max-len
        return await fetch(`${ENDPOINT}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    console.log(data.dealsPipelines);
                    return {success: true, dealsPipelines: data.dealsPipelines};
                } else {
                    return {success: false, reason: "internal server error"};
                }
            }).catch((error) => {
                console.error(error);
                return {success: false, reason: "internal server error"};
            });
    }
}

module.exports = Hubspot;
