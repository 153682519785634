import styles from "./Tooltip.module.css";
const Tooltip = (props) => {
    const handleCloseClick = (e) => {
        e.stopPropagation();
        props.tooltipCloseClick();
    };

    return (

        <div className={styles.frameContainer}>
            <div className={styles.frameDiv}>
                <div className={styles.h2HeadingFrame}>
                    <b className={styles.h2Heading7}>Confidence Score</b>
                </div>
                <img
                    className={styles.closeIcon}
                    loading="lazy"
                    alt=""
                    src="/close-1@2x.png"
                    onClick={(e) => handleCloseClick(e)}
                />
            </div>
            <div className={styles.h2HeadingGroup}>
                <div className={styles.h2Heading8}>
                    <p className={styles.addyGivesYou}>
                    Addy gives you an estimate of its answers.
                    </p>
                    <p className={styles.blankLine}>&nbsp;</p>
                    <div className={styles.tooltipExplainerHeader}>
                        <div className={styles.frameInner} />
                        <p className={styles.spotOnHasAScoreOf910}>

                            <span className={styles.spotOn}>Spot on</span>
                            <span className={styles.hasAScore}>
                                {" "}
                      has a score of 9-10
                            </span>
                        </p>
                    </div>

                    <p className={styles.itsLikelyDoesnt}>
                    It likely doesn't need corrections.
                    </p>
                    <p className={styles.blankLine1}>&nbsp;</p>
                    <div className={styles.tooltipExplainerHeader}>
                        <div className={styles.ellipseDiv} />
                        <p className={styles.spotOnHasAScoreOf910}>

                            <span className={styles.spotOn}>Acceptable</span>
                            <span className={styles.hasAScore}>
                                {" "}
                      has a score of 7-8
                            </span>
                        </p>
                    </div>
                    <p className={styles.youMayNeed}>
                    You may need to double-check this information.
                    </p>
                    <p className={styles.blankLine2}>&nbsp;</p>
                    <div className={styles.tooltipExplainerHeader}>
                        <div className={styles.frameChild1} />
                        <p className={styles.spotOnHasAScoreOf910}>

                            <span className={styles.spotOn}>Inaccurate</span>
                            <span className={styles.hasAScore}>
                                {" "}
                      is below 6
                            </span>
                        </p>
                    </div>
                    <p className={styles.addyDidntRecognize}>
                    Addy didn't recognize the data or couldn't find it.
                    </p>
                </div>
            </div>
            <img className={styles.polygonIcon} alt="" src="/polygon-1.svg" />
        </div>
    );
};

export default Tooltip;
